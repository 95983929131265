import Dexie from "dexie"
import { Workspace, TabContent, Setting, Flag } from "src/types"

export const DATABASE_NAME = "json_tabs_database"

export default class Database extends Dexie {
  workspace: Dexie.Table<Workspace, number>
  tabContent: Dexie.Table<TabContent, string>
  setting: Dexie.Table<Setting, string>
  flag: Dexie.Table<Flag, string>

  constructor() {
    super(DATABASE_NAME)

    this.version(1).stores({
      workspace: "id,tabs",
      tab_content: "id",
      setting: "id",
      flag: "id",
    })

    this.workspace = this.table("workspace")
    this.tabContent = this.table("tab_content")
    this.setting = this.table("setting")
    this.flag = this.table("flag")
  }
}
