import { Input } from "@chakra-ui/react"
import React from "react"

type UploadHandlerArgs = {
  onUpload(data: string): void
}

const FileUploadContext = React.createContext<
  ((args: UploadHandlerArgs) => void) | undefined
>(undefined)

export const FileUploadProvider: React.FC = ({ children }) => {
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null)
  const uploadCallbackRef = React.useRef<((data: string) => void) | null>(null)

  const uploadHandler = ({ onUpload }: UploadHandlerArgs) => {
    if (hiddenFileInput?.current) {
      uploadCallbackRef.current = onUpload
      hiddenFileInput.current.click()
    }
  }

  const onFileInputChange = async (event: any) => {
    if (!event.target?.files?.length || !event.target.files[0]) return

    const [fileUploaded, fileReader] = [event.target.files[0], new FileReader()]

    fileReader.onload = () => {
      if (typeof fileReader.result === "string" && uploadCallbackRef.current) {
        uploadCallbackRef.current(fileReader.result)
      }
    }

    fileReader.readAsText(fileUploaded)
  }

  return (
    <FileUploadContext.Provider value={uploadHandler}>
      <Input
        type="file"
        ref={hiddenFileInput}
        onChange={onFileInputChange}
        onClick={(event: any) => {
          // Clear input so user can upload same file multiple times
          event.target.value = ""
        }}
        display="none"
        accept=".json,.txt,.js,.ts"
      />
      {children}
    </FileUploadContext.Provider>
  )
}

export const useUploadHandler = () => {
  const context = React.useContext(FileUploadContext)
  if (context === undefined) {
    throw new Error("useUploadHandler must be used within a FileUploadProvider")
  }
  return context
}
