import React from "react"
import { Flex, Spinner } from "@chakra-ui/react"
import { DragDropContext } from "react-beautiful-dnd"
import { useWorkspacesContext } from "src/context/WorkspacesProvider"
import useOnDragEnd from "src/hooks/handlers/useOnDragEnd"
import useBackgroundColor from "src/hooks/theme/useBackgroundColor"
import { useSettingsContext } from "src/context/SettingsProvider"
import Workspaces from "src/components/layout/workspaces/Workspaces"

const Main: React.FC = () => {
  const backgroundColor = useBackgroundColor()
  const { isSettingsLoading } = useSettingsContext()
  const { workspaces, areWorkspacesLoading } = useWorkspacesContext()
  const onDragEnd = useOnDragEnd(workspaces)

  return (
    <Flex
      mt={4}
      mb="0.625rem"
      height="calc(100vh - 5rem)"
      width="100%"
      justify="center"
      backgroundColor={backgroundColor}
    >
      {!isSettingsLoading && !areWorkspacesLoading ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Workspaces />
        </DragDropContext>
      ) : (
        <Flex height="100%" width="100%" justify="center" align="center">
          <Spinner size="xl" data-testid="main-spinner" />
        </Flex>
      )}
    </Flex>
  )
}

export default Main
