import React from "react"
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const Mode: React.FC = () => {
  const { workspace, activeTab, setTabMode } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()

  const tabMode = activeTab?.mode

  const onChange = (value: any) => {
    setTabMode({ tabMode: value, tabId: workspace.activeTabId }).then(() =>
      analytics.logEvent(EVENTS.WORKSPACE_MODE_TOGGLE, { mode: value })
    )
  }

  return (
    <Menu placement="bottom">
      <MenuButton
        as={Button}
        size="xs"
        variant="ghost"
        onClick={() => {
          analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, { name: "mode" })
        }}
      >
        Mode
      </MenuButton>
      <Portal>
        <MenuList minWidth={200}>
          <MenuOptionGroup value={tabMode} type="radio" onChange={onChange}>
            <MenuItemOption value="text">Text Editor</MenuItemOption>
            <MenuItemOption value="tree">Tree Viewer</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default Mode
