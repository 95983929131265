import {
  Button,
  Center,
  Divider,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"
import { FaLinkedin, FaGithub } from "react-icons/fa"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import DrawerTrigger from "src/components/common/DrawerTrigger"
import PrivacyDrawer from "src/components/drawers/PrivacyDrawer"
import { EVENTS } from "src/constants/analytics"

const Footer: React.FC = React.memo(() => {
  const textColor = useColorModeValue("gray.800", "gray.200")
  const buttonStyles = useColorModeValue(
    { color: "gray.800", _active: { color: "gray.600" } },
    { color: "gray.100", _active: { color: "gray.300" } }
  )

  const { analytics } = useFirebaseContext()

  return (
    <Center as="footer" px={6} my={8}>
      <Stack
        direction="row"
        justify="flex-end"
        maxW="70rem"
        flex={1}
        zIndex={1}
      >
        <Stack direction="column">
          <Stack direction="row" justify="flex-end">
            <Text color={textColor} fontSize="sm">
              Copyright &#169; JSON Tabs {new Date().getFullYear()}
            </Text>
            <DrawerTrigger
              placement="bottom"
              renderButton={({ onClick }) => (
                <Button
                  variant="link"
                  fontSize="sm"
                  onClick={() => {
                    analytics.logEvent(EVENTS.PRIVACY_POLICY_OPEN)
                    onClick()
                  }}
                  {...buttonStyles}
                >
                  Privacy
                </Button>
              )}
              renderDrawer={PrivacyDrawer}
            />
            <Divider orientation="vertical" />
            <Button
              as={Link}
              href="mailto:feedback@jsontabs.com"
              target="_blank"
              variant="link"
              fontSize="sm"
              {...buttonStyles}
            >
              Feedback
            </Button>
          </Stack>
          <Stack direction="row" align="center" justify="flex-end">
            <Text color={textColor} fontSize="sm">
              Fo️rged in the fires of Mount Doom 🌋️ by Jacob Wood
            </Text>
            <Divider orientation="vertical" />
            <Center
              as={Link}
              aria-label="GitHub"
              href="https://github.com/jacoblandonwood"
              target="blank"
              onClick={() => analytics.logEvent(EVENTS.GITHUB_LINK_CLICK)}
            >
              <Icon as={FaGithub} height={4} width={4} {...buttonStyles} />
            </Center>
            <Center
              as={Link}
              aria-label="LinkedIn"
              href="https://linkedin.com/in/jacoblandonwood"
              target="blank"
              onClick={() => analytics.logEvent(EVENTS.LINKEDIN_LINK_CLICK)}
            >
              <Icon as={FaLinkedin} height={4} width={4} {...buttonStyles} />
            </Center>
          </Stack>
        </Stack>
      </Stack>
    </Center>
  )
})

export default Footer
