import { Center } from "@chakra-ui/react"
import React from "react"
import { IconType } from "react-icons"
import useBackgroundColor from "src/hooks/theme/useBackgroundColor"
import usePrimaryColor from "src/hooks/theme/usePrimaryColor"

type CardIconProps = {
  icon: IconType
}

const CardIcon: React.FC<CardIconProps> = ({ icon }) => {
  const primaryColor = usePrimaryColor()
  const backgroundColor = useBackgroundColor()
  const Icon = icon

  return (
    <Center h={9} w={9} bgColor={primaryColor} borderRadius="100%">
      <Icon color={backgroundColor} size={18} />
    </Center>
  )
}

export default CardIcon
