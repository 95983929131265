import { DraggableLocation } from "react-beautiful-dnd"
import { NEW_TAB_DEFAULTS } from "src/constants"
import { Workspace } from "src/types"
import { v4 as uuid } from "uuid"

export type ReorderWorkspacesArgs = {
  workspaces: Workspace[]
  source: DraggableLocation
  destination: DraggableLocation
  newTabDefaultName: string
}

export const reorderWorkspaces = ({
  workspaces,
  source,
  destination,
  newTabDefaultName,
}: ReorderWorkspacesArgs): Workspace[] => {
  const [srcId, destId] = [+source.droppableId, +destination.droppableId]
  const newSrcTabs = Array.from(workspaces[srcId].tabs)
  const newDestTabs = Array.from(workspaces[destId].tabs)
  const target = newSrcTabs[source.index]

  const reorderSingleWorkspace = () => {
    // Remove source element
    newSrcTabs.splice(source.index, 1)
    // Add source element at destination
    newSrcTabs.splice(destination.index, 0, target)

    return [
      {
        id: destId,
        tabs: newSrcTabs,
        activeTabId: newSrcTabs[destination.index].id,
      },
    ]
  }

  const reorderMultipleWorkspaces = () => {
    // Remove from original
    newSrcTabs.splice(source.index, 1)
    // Insert into next
    newDestTabs.splice(destination.index, 0, target)
    // Add new tab if src tabs are empty
    if (!newSrcTabs.length)
      newSrcTabs.push({
        id: uuid(),
        name: newTabDefaultName,
        ...NEW_TAB_DEFAULTS,
      })

    return workspaces.reduce<Workspace[]>((acc, workspace, idx) => {
      if (idx === srcId) {
        const isDraggedTabActive = target.id === workspaces[srcId].activeTabId
        const activeTabId = isDraggedTabActive
          ? newSrcTabs[Math.min(source.index, newSrcTabs.length - 1)].id
          : workspaces[srcId].activeTabId
        acc.push({ id: srcId, tabs: newSrcTabs, activeTabId })
      } else if (idx === destId) {
        acc.push({
          id: destId,
          tabs: newDestTabs,
          activeTabId: newDestTabs[destination.index].id,
        })
      }
      return acc
    }, [])
  }

  return srcId === destId
    ? reorderSingleWorkspace()
    : reorderMultipleWorkspaces()
}
