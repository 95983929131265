import { reorderWorkspaces } from "src/utils/reorderUtils"
import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import React from "react"
import { Workspace } from "src/types"
import { useTabsContext } from "src/context/TabsProvider"
import { DraggableLocation } from "react-beautiful-dnd"

const useReorderTabsMutation = () => {
  const queryClient = useQueryClient()
  const db = useDb()
  const { newTabDefaultName } = useTabsContext()
  const newWorkspacesRef = React.useRef<Workspace[]>()

  return useMutation(
    async () => {
      const workspaces: any = newWorkspacesRef.current
      await db.workspace.bulkPut(workspaces)
    },
    {
      onMutate: (params: {
        workspaces: Workspace[]
        source: DraggableLocation
        destination: DraggableLocation
      }) => {
        newWorkspacesRef.current = reorderWorkspaces({
          ...params,
          newTabDefaultName,
        })
        newWorkspacesRef.current.forEach((w) =>
          queryClient.setQueryData(`workspace:${w.id}`, w)
        )
      },
    }
  )
}

export default useReorderTabsMutation
