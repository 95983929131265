import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"

const useSetFlagMutation = (id: string) => {
  const queryClient = useQueryClient()
  const db = useDb()

  return useMutation(
    async (isOn: boolean) => {
      await db.flag.put({ id, isOn })
    },
    {
      onMutate: (isOn) => {
        queryClient.setQueryData(`flag:${id}`, { id, isOn })
      },
    }
  )
}

export default useSetFlagMutation
