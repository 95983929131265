const defaultSettings: { id: string; value: number | boolean | string }[] = [
  {
    id: "editor_spacing",
    value: 4,
  },
  {
    id: "editor_vim_enabled",
    value: false,
  },
  {
    id: "editor_font_size",
    value: 14,
  },
  {
    id: "new_tab_form_enabled",
    value: true,
  },
  {
    id: "h_split_screen_enabled",
    value: false,
  },
  {
    id: "v_split_screen_enabled",
    value: false,
  },
]

export default defaultSettings
