import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useClipboard,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react"
import React from "react"
import { PropertyName } from "src/components/workspace/viewer/Viewer"
import { EVENTS } from "src/constants/analytics"
import { useFirebaseContext } from "src/context/FirebaseProvider"

type PathProps = {
  pathToField: PropertyName[]
}

const ALPHA_NUMERIC_REGEX = new RegExp(/^[A-Za-z0-9_]+$/)

const Path: React.FC<PathProps> = React.memo(({ pathToField }) => {
  const backgroundColor = useColorModeValue("#f7fafc", "#1f2430")
  const menuButtonHoverColor = useColorModeValue("#8A91991A", "#191E2A")
  const borderLeftColor = useColorModeValue("gray.500", "whiteAlpha.600")
  const toast = useToast()
  const { analytics } = useFirebaseContext()

  const pathReversed = Array.from(pathToField).reverse()

  const triggerToast = () =>
    toast({
      title: "Copied path to field",
      duration: 1000,
      status: "success",
      isClosable: true,
      position: "bottom",
    })

  const { dotNotationList, bracketNotationList } = pathReversed.reduce<{
    dotNotationList: string[]
    bracketNotationList: string[]
  }>(
    (acc, key, idx) => {
      if (idx === 0) return acc

      if (typeof key === "number") {
        acc.dotNotationList.push(`[${key}]`)
        acc.bracketNotationList.push(`[${key}]`)
      } else if (!ALPHA_NUMERIC_REGEX.test(key)) {
        acc.dotNotationList.push(`["${key}"]`)
        acc.bracketNotationList.push(`["${key}"]`)
      } else {
        acc.dotNotationList.push(`.${key}`)
        acc.bracketNotationList.push(`["${key}"]`)
      }
      return acc
    },
    { dotNotationList: [], bracketNotationList: [] }
  )

  const dotNotation = dotNotationList.join("")
  const { onCopy: onDotNotationCopy } = useClipboard(dotNotation)

  const bracketNotation = bracketNotationList.join("")
  const { onCopy: onBracketNotationCopy } = useClipboard(bracketNotation)

  return (
    <Flex
      borderTopRadius="base"
      direction="row"
      borderBottomWidth={1}
      bgColor={backgroundColor}
      overflow="hidden"
    >
      <Menu placement="bottom">
        <MenuButton
          _hover={{ backgroundColor: menuButtonHoverColor }}
          onClick={() => analytics.logEvent(EVENTS.VIEWER_PATH_CLICK)}
        >
          <HStack direction="row" py={1} px={2}>
            {pathReversed.map((key, idx) => (
              <React.Fragment key={idx.toString()}>
                {idx > 0 && (
                  <Box
                    sx={{
                      borderTop: "6px solid transparent",
                      borderBottom: "6px solid transparent",
                      borderLeft: "9px solid",
                      borderLeftColor,
                    }}
                  />
                )}
                <Text
                  fontSize="sm"
                  lineHeight="normal"
                  whiteSpace="nowrap"
                  wordBreak="break-all"
                >
                  {key}
                </Text>
              </React.Fragment>
            ))}
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuGroup title="Copy path to field">
            <MenuItem
              onClick={() => {
                onBracketNotationCopy()
                triggerToast()
                analytics.logEvent(EVENTS.PATH_COPY, {
                  type: "bracket_notation",
                })
              }}
            >
              Bracket notation
            </MenuItem>
            <MenuItem
              onClick={() => {
                onDotNotationCopy()
                triggerToast()
                analytics.logEvent(EVENTS.PATH_COPY, { type: "dot_notation" })
              }}
            >
              Dot notation
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  )
})

export default Path
