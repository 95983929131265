import { Box } from "@chakra-ui/react"
import React from "react"
import WorkspaceComponent from "src/components/workspace/Workspace"
import { useWorkspacesContext } from "src/context/WorkspacesProvider"
import Split from "react-split"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const QuadSplitPane: React.FC = () => {
  const { workspaces } = useWorkspacesContext()
  const { analytics } = useFirebaseContext()
  const onHorizontalDragEnd = React.useCallback(() => {
    analytics.logEvent(EVENTS.SPLIT_PANE_DRAG, {
      direction: "horizontal",
      name: "quad_split_pane",
    })
  }, [analytics])
  const onVerticalDragEnd = React.useCallback(() => {
    analytics.logEvent(EVENTS.SPLIT_PANE_DRAG, {
      direction: "vertical",
      name: "quad_split_pane",
    })
  }, [analytics])

  return (
    <Box
      height="100%"
      width="100%"
      maxW="96rem"
      px={6}
      data-testid="quad_split_pane"
    >
      <Box
        as={Split}
        direction="horizontal"
        flexDirection="row"
        minSize={300}
        display="flex"
        height="100%"
        width="100%"
        gutterSize={15}
        onDragEnd={onHorizontalDragEnd}
      >
        <Box
          as={Split}
          direction="vertical"
          display="flex"
          minSize={200}
          flexDirection="column"
          height="100%"
          width="100%"
          gutterSize={15}
          onDragEnd={onVerticalDragEnd}
        >
          <WorkspaceComponent workspace={workspaces[0]} />
          <WorkspaceComponent workspace={workspaces[2]} />
        </Box>
        <Box
          as={Split}
          direction="vertical"
          minSize={200}
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          gutterSize={15}
          onDragEnd={onVerticalDragEnd}
        >
          <WorkspaceComponent workspace={workspaces[1]} />
          <WorkspaceComponent workspace={workspaces[3]} />
        </Box>
      </Box>
    </Box>
  )
}

export default QuadSplitPane
