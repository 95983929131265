import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import { Workspace } from "src/types"

const useSetActiveTabMutation = (workspace: Workspace) => {
  const queryClient = useQueryClient()
  const db = useDb()

  return useMutation(
    async (tabId: string) => {
      await db.workspace.update(workspace.id, { activeTabId: tabId })
    },
    {
      onMutate: (tabId: string) => {
        queryClient.setQueryData(`workspace:${workspace.id}`, {
          ...workspace,
          activeTabId: tabId,
        })
      },
    }
  )
}

export default useSetActiveTabMutation
