import { useQuery } from "react-query"
import { useDb } from "src/context/DbProvider"
import { Workspace } from "src/types"
import { v4 as uuid } from "uuid"

const generateNewWorkspace = (workspaceId: number): Workspace => {
  const tabId = uuid()
  const name = (workspaceId + 1).toString()

  return {
    id: workspaceId,
    tabs: [{ id: tabId, name, mode: "text" }],
    activeTabId: tabId,
  }
}

const useWorkspaceQuery = (id: number) => {
  const db = useDb()

  return useQuery(`workspace:${id}`, async () => {
    const workspace = await db.workspace.get(id)
    if (workspace) return workspace

    const newWorkspace = generateNewWorkspace(id)
    await db.workspace.put(newWorkspace)
    return newWorkspace
  })
}

export default useWorkspaceQuery
