import React from "react"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react"
import TabNameForm from "src/components/forms/TabNameForm"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import CreateTabButton from "src/components/workspace/tabs/createTab/CreateTabButton"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const CreateTabPopover: React.FC = () => {
  const firstFieldRef = React.useRef<HTMLInputElement | null>(null)
  const { createTab } = useWorkspaceContext()
  const [isOpen, setIsOpen] = React.useState(false)
  const { onClose, onOpen } = React.useMemo(
    () => ({ onClose: () => setIsOpen(false), onOpen: () => setIsOpen(true) }),
    [setIsOpen]
  )
  const { analytics } = useFirebaseContext()

  return (
    <Popover
      initialFocusRef={firstFieldRef}
      placement="bottom"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <CreateTabButton onClick={onOpen} />
      </PopoverTrigger>
      <PopoverContent zIndex={4} p={5}>
        <PopoverArrow />
        <PopoverCloseButton />
        <TabNameForm
          firstFieldRef={firstFieldRef}
          onClose={onClose}
          submitMutation={(params) =>
            createTab(params).then(() =>
              analytics.logEvent(EVENTS.TAB_CREATE, {
                custom_name_provided: true,
              })
            )
          }
          buttonText="Create Tab"
          defaultValues={{ tabName: "" }}
        />
      </PopoverContent>
    </Popover>
  )
}

export default CreateTabPopover
