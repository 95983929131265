import React from "react"
import {
  Flex,
  Heading,
  useColorMode,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  Stack,
  Badge,
} from "@chakra-ui/react"
import { VscSplitHorizontal, VscSplitVertical } from "react-icons/vsc"
import DrawerTrigger from "src/components/common/DrawerTrigger"
import SettingsDrawer from "src/components/drawers/SettingsDrawer"
import TabSearch from "src/components/search/TabSearch"
import { MoonIcon, SettingsIcon, SunIcon } from "@chakra-ui/icons"
import useBackgroundColor from "src/hooks/theme/useBackgroundColor"
import usePrimaryColor from "src/hooks/theme/usePrimaryColor"
import usePrimaryColorScheme from "src/hooks/theme/usePrimaryColorScheme"
import { useSettingsContext } from "src/context/SettingsProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import UpdateVersionButton from "src/components/common/UpdateVersionButton"
import { EVENTS } from "src/constants/analytics"

export const horizontalSplitScreenLabel = "Toggle horizontal split screen"
export const verticalSplitScreenLabel = "Toggle vertical split screen"

const Header: React.FC = () => {
  const primaryColor = usePrimaryColor()
  const primaryColorScheme = usePrimaryColorScheme()
  const { toggleColorMode } = useColorMode()
  const backgroundColor = useBackgroundColor()

  const { analytics } = useFirebaseContext()

  const toggleColorModeLabel = useColorModeValue(
    "Switch to dark mode",
    "Switch to light mode"
  )
  const toggleColorModeIcon = useColorModeValue(<MoonIcon />, <SunIcon />)

  const {
    isHorizontalSplitScreenEnabled,
    setIsHorizontalSplitScreenEnabled,
    isVerticalSplitScreenEnabled,
    setIsVerticalSplitScreenEnabled,
    isSettingsLoading,
  } = useSettingsContext()

  return (
    <Flex
      as="header"
      position="sticky"
      top="-1px"
      mt="-1px"
      zIndex={4}
      borderBottomWidth={1}
      width="100%"
      height="3.5rem"
      backgroundColor={backgroundColor}
      justify="center"
    >
      <Flex
        align="center"
        justify="space-between"
        width="100%"
        height="100%"
        paddingX={6}
        maxW="96rem"
        position="relative"
      >
        <Stack direction="row" align="center">
          <Heading
            as="h1"
            size="lg"
            letterSpacing="tight"
            fontSize="1.5rem"
            lineHeight={8}
          >
            <Text as="span" color={primaryColor}>
              JSON
            </Text>{" "}
            Tabs
          </Heading>
          <Badge
            colorScheme={primaryColorScheme}
            mt="0.25rem !important"
            variant="subtle"
            textTransform="none"
          >
            v{process.env.REACT_APP_VERSION}
          </Badge>
          <UpdateVersionButton />
        </Stack>
        <Flex id="header-right-section" direction="row" align="center">
          <TabSearch />
          <Tooltip
            label={horizontalSplitScreenLabel}
            aria-label={horizontalSplitScreenLabel}
            placement="bottom"
          >
            <IconButton
              aria-label={horizontalSplitScreenLabel}
              fontSize={20}
              size="md"
              variant="ghost"
              icon={<VscSplitHorizontal />}
              color={isHorizontalSplitScreenEnabled ? primaryColor : "gray.500"}
              onClick={() =>
                setIsHorizontalSplitScreenEnabled(
                  !isHorizontalSplitScreenEnabled
                )
              }
              isLoading={isSettingsLoading}
              ml={2}
            />
          </Tooltip>
          <Tooltip
            label={verticalSplitScreenLabel}
            aria-label={verticalSplitScreenLabel}
            placement="bottom"
          >
            <IconButton
              aria-label={verticalSplitScreenLabel}
              fontSize={20}
              size="md"
              variant="ghost"
              icon={<VscSplitVertical />}
              color={isVerticalSplitScreenEnabled ? primaryColor : "gray.500"}
              onClick={() =>
                setIsVerticalSplitScreenEnabled(!isVerticalSplitScreenEnabled)
              }
              isLoading={isSettingsLoading}
              ml={2}
            />
          </Tooltip>
          <Tooltip
            label={toggleColorModeLabel}
            aria-label={toggleColorModeLabel}
            placement="bottom"
          >
            <IconButton
              ml={2}
              aria-label={toggleColorModeLabel}
              color="gray.500"
              fontSize={20}
              size="md"
              variant="ghost"
              icon={toggleColorModeIcon}
              onClick={toggleColorMode}
            />
          </Tooltip>
          <DrawerTrigger
            placement="right"
            renderButton={({ onClick }) => (
              <Tooltip
                label="Settings"
                aria-label="Settings"
                placement="bottom"
              >
                <IconButton
                  ml={2}
                  aria-label="Settings"
                  color="gray.500"
                  fontSize={20}
                  size="md"
                  variant="ghost"
                  icon={<SettingsIcon />}
                  onClick={() => {
                    analytics.logEvent(EVENTS.HEADER_SETTINGS_OPEN)
                    onClick()
                  }}
                />
              </Tooltip>
            )}
            renderDrawer={SettingsDrawer}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
