import {
  Box,
  Heading,
  useColorModeValue,
  Text,
  Flex,
  VStack,
} from "@chakra-ui/react"
import React from "react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useTabContent from "src/hooks/data/useTabContent"
import BodyLoader from "src/components/workspace/common/BodyLoader"
import { parseJson } from "src/utils/stringUtils"
import ObjectViewer from "src/components/workspace/viewer/object/ObjectViewer"
import Path from "src/components/workspace/viewer/Path"
import { TabContent } from "src/types"

type ViewerWithPathProps = {
  parsedTabContent: any
}

export type PropertyName = string | number

const ViewerWithPath: React.FC<ViewerWithPathProps> = ({
  parsedTabContent,
}) => {
  const propName = React.useMemo(
    () => (Array.isArray(parsedTabContent) ? "array" : "object"),
    [parsedTabContent]
  )
  const [pathToField, setPathToField] = React.useState<PropertyName[]>([])

  React.useEffect(() => {
    setPathToField([propName])
  }, [parsedTabContent, setPathToField, propName])

  const buildPathToField = React.useCallback(
    (propNames: PropertyName[]) => {
      propNames.push(propName)
      setPathToField(propNames)
    },
    [setPathToField, propName]
  )

  return (
    <Flex direction="column" position="relative" height="100%">
      <Path pathToField={pathToField} />
      <Flex
        direction="column"
        overflowY="auto"
        position="absolute"
        top="26px"
        left={0}
        width="100%"
        maxH="calc(100% - 26px)"
      >
        <ObjectViewer
          value={parsedTabContent}
          parentBuildPathToField={buildPathToField}
        />
      </Flex>
    </Flex>
  )
}

const Viewer: React.FC = () => {
  const backgroundColor = useColorModeValue("gray.50", "#1f2430")
  const { workspace } = useWorkspaceContext()
  const { tabContent, isTabContentLoading } = useTabContent(
    workspace.activeTabId
  )
  const tabContentHasLoaded =
    !isTabContentLoading && typeof tabContent?.data !== "undefined"

  const parsedTabContent = React.useMemo<TabContent>(() => {
    if (typeof tabContent?.data === "undefined") return undefined

    try {
      return parseJson(tabContent?.data)
    } catch (error) {
      return undefined
    }
  }, [tabContent])

  const bodyRendered = React.useMemo(() => {
    if (!tabContentHasLoaded) {
      return <BodyLoader />
    }

    if (typeof parsedTabContent === "undefined") {
      return (
        <VStack height="100%" width="100%" justify="center" px={2} spacing={0}>
          <Heading as="h3" fontSize="lg" textAlign="center">
            Tree Viewer requires valid JSON
          </Heading>
          <Text maxW={64} textAlign="center" fontSize="sm">
            Switch mode to "Text Editor", enter valid JSON, and switch mode back
            to "Tree Viewer" to try again.
          </Text>
        </VStack>
      )
    }

    return <ViewerWithPath parsedTabContent={parsedTabContent} />
  }, [tabContentHasLoaded, parsedTabContent])

  return (
    <Box flexGrow={1} backgroundColor={backgroundColor} borderRadius="0.25rem">
      {bodyRendered}
    </Box>
  )
}

export default Viewer
