import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import { useTabsContext } from "src/context/TabsProvider"
import { Workspace } from "src/types"
import { workspaceWithTabClosed } from "src/utils/workspaceUtils"

const useCloseTabMutation = (workspace: Workspace) => {
  const queryClient = useQueryClient()
  const db = useDb()
  const { newTabDefaultName } = useTabsContext()
  const newWorkspaceRef = React.useRef<Workspace>()

  return useMutation(
    async (tabId: string) => {
      const workspace: any = newWorkspaceRef.current

      await Promise.all([
        db.workspace.put(workspace),
        db.tabContent.delete(tabId),
      ])
    },
    {
      onMutate: (tabId: string) => {
        const newWorkspace = workspaceWithTabClosed(
          workspace,
          tabId,
          newTabDefaultName
        )
        newWorkspaceRef.current = newWorkspace
        queryClient.setQueryData(`workspace:${workspace.id}`, newWorkspace)
      },
    }
  )
}

export default useCloseTabMutation
