import { Box } from "@chakra-ui/react"
import React from "react"
import WorkspaceComponent from "src/components/workspace/Workspace"
import { useWorkspacesContext } from "src/context/WorkspacesProvider"
import Split from "react-split"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const VerticalSplitPane: React.FC = () => {
  const { workspaces } = useWorkspacesContext()
  const { analytics } = useFirebaseContext()
  const onDragEnd = React.useCallback(() => {
    analytics.logEvent(EVENTS.SPLIT_PANE_DRAG, {
      direction: "vertical",
      name: "vertical_split_pane",
    })
  }, [analytics])

  return (
    <Box
      height="100%"
      width="100%"
      maxW="96rem"
      px={6}
      data-testid="vertical-split-pane"
    >
      <Box
        as={Split}
        direction="vertical"
        flexDirection="column"
        minSize={200}
        display="flex"
        height="100%"
        width="100%"
        gutterSize={15}
        onDragEnd={onDragEnd}
      >
        <WorkspaceComponent workspace={workspaces[0]} />
        <WorkspaceComponent workspace={workspaces[1]} />
      </Box>
    </Box>
  )
}

export default VerticalSplitPane
