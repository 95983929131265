import React from "react"
import { Button, useToast } from "@chakra-ui/react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useTabContent from "src/hooks/data/useTabContent"
import useSetTabContentMutation from "src/hooks/mutations/useSetTabContentMutation"
import { parseJson } from "src/utils/stringUtils"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const Minify: React.FC = () => {
  const { workspace, activeTab } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const setTabContentMutation = useSetTabContentMutation()
  const toast = useToast()

  const onClick = async () => {
    analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, { name: "minify" })

    if (typeof tabContent?.data === "undefined") return

    try {
      const obj = parseJson(tabContent.data)

      await setTabContentMutation.mutateAsync({
        id: workspace.activeTabId,
        data: JSON.stringify(obj),
      })
    } catch (error) {
      toast({
        title: "Unable to parse JSON",
        description: error.message,
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      })
    }
  }

  return (
    <Button
      size="xs"
      variant="ghost"
      onClick={onClick}
      isDisabled={activeTab?.mode !== "text"}
    >
      Minify
    </Button>
  )
}

export default Minify
