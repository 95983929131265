import React from "react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useTabContent from "src/hooks/data/useTabContent"

type PreviousTabContent = {
  id: string
  data?: string
  isCurrent: boolean
}

const usePreviousTabContent = () => {
  const { workspace } = useWorkspaceContext()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const [previousTabContent, setPreviousTabContent] = React.useState<
    PreviousTabContent
  >()
  const previousTabContentDataRef = React.useRef<string>()

  React.useEffect(() => {
    if (typeof tabContent?.data === "undefined") return

    if (workspace.activeTabId !== previousTabContent?.id) {
      setPreviousTabContent({ ...tabContent, isCurrent: true })
      previousTabContentDataRef.current = tabContent.data
    } else if (tabContent.data !== previousTabContentDataRef.current) {
      setPreviousTabContent({
        id: tabContent.id,
        data: previousTabContentDataRef.current,
        isCurrent: false,
      })
      previousTabContentDataRef.current = tabContent.data
    }
  }, [
    workspace.activeTabId,
    tabContent,
    previousTabContent,
    setPreviousTabContent,
  ])

  return previousTabContent
}

export default usePreviousTabContent
