import React from "react"
import {
  Stack,
  Button,
  Divider,
  Flex,
  useToast,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import useSetTabContentMutation from "src/hooks/mutations/useSetTabContentMutation"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import urlRegex from "url-regex"
import { useUploadHandler } from "src/context/FileUploadProvider"
import usePrimaryColorScheme from "src/hooks/theme/usePrimaryColorScheme"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

type FormProps = {
  firstFieldRef: React.MutableRefObject<HTMLInputElement | null>
  onClose: () => void
  isOpen: boolean
}

const LoadDataForm: React.FC<FormProps> = ({
  firstFieldRef,
  onClose,
  isOpen,
}) => {
  const { workspace } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const primaryColorScheme = usePrimaryColorScheme()
  const { register, handleSubmit, errors, reset } = useForm()
  const setTabContentMutation = useSetTabContentMutation()
  const toast = useToast()
  const [isFetching, setIsFetching] = React.useState(false)
  const uploadHandler = useUploadHandler()
  const inputId = `jsonUrl-${workspace.id}`

  React.useEffect(() => {
    if (isOpen) {
      reset()
      setIsFetching(false)
    }
  }, [isOpen, reset, setIsFetching])

  const onSubmit = handleSubmit(async ({ jsonUrl }) => {
    analytics.logEvent(EVENTS.WORKSPACE_URL_LOAD_SUBMIT)
    setIsFetching(true)

    try {
      const response = await fetch(jsonUrl)
      const data = await response.text()
      await setTabContentMutation.mutateAsync({
        id: workspace.activeTabId,
        data,
      })
      toast({
        title: "Load URL succeeded",
        duration: 3000,
        status: "success",
        isClosable: true,
        position: "bottom",
      })
      analytics.logEvent(EVENTS.WORKSPACE_URL_LOAD_SUCCESS)
    } catch (err) {
      toast({
        title: "Load URL failed",
        description: "Unable to fetch/parse JSON at specified URL",
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      })
      analytics.logEvent(EVENTS.WORKSPACE_URL_LOAD_FAIL, { url: jsonUrl })
    } finally {
      onClose()
    }
  })

  const onUploadClick = () =>
    uploadHandler({
      onUpload: (data) => {
        analytics.logEvent(EVENTS.WORKSPACE_FILE_UPLOAD)
        setTabContentMutation.mutate({ id: workspace.activeTabId, data })
        toast({
          title: "File upload succeeded",
          duration: 3000,
          status: "success",
          isClosable: true,
          position: "bottom",
        })
        onClose()
      },
    })

  return (
    <Flex direction="column">
      <form onSubmit={onSubmit}>
        <Stack spacing={4} direction="column">
          <FormControl>
            <FormLabel htmlFor={inputId}>Load from URL</FormLabel>
            <Input
              ref={(ref: any) => {
                firstFieldRef.current = ref
                register(ref, {
                  validate: (val) => {
                    const isValid = urlRegex({ exact: true }).test(val)
                    if (!isValid) {
                      toast({
                        title: "Invalid URL",
                        description: "Please enter a valid URL and try again.",
                        duration: 3000,
                        status: "error",
                        isClosable: true,
                        position: "bottom",
                      })
                    }
                    return isValid
                  },
                })
              }}
              id={inputId}
              placeholder="https://foo.json"
              name="jsonUrl"
              isInvalid={!!errors.jsonUrl}
              isDisabled={isFetching}
            />
          </FormControl>
          <Button
            colorScheme={primaryColorScheme}
            type="submit"
            isLoading={isFetching}
            isDisabled={isFetching}
          >
            Load URL
          </Button>
        </Stack>
      </form>
      <Divider orientation="horizontal" marginY={6} />
      <Button
        colorScheme={primaryColorScheme}
        variant="outline"
        onClick={onUploadClick}
        isLoading={isFetching}
        isDisabled={isFetching}
      >
        Upload File
      </Button>
    </Flex>
  )
}

export default LoadDataForm
