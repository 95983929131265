import { AddIcon } from "@chakra-ui/icons"
import { IconButton, useColorModeValue } from "@chakra-ui/react"
import React from "react"

type CreateTabButtonProps = {
  onClick(): void
}

const CreateTabButton = React.forwardRef<
  HTMLButtonElement,
  CreateTabButtonProps
>(({ onClick }, ref) => {
  const statefulStyles = useColorModeValue(
    {
      _hover: { backgroundColor: "gray.300" },
      _active: { backgroundColor: "gray.400" },
    },
    {}
  )

  return (
    <IconButton
      ref={ref}
      aria-label="New tab"
      icon={<AddIcon />}
      size="xs"
      variant="ghost"
      ml={2}
      mt={1}
      {...statefulStyles}
      onClick={onClick}
    />
  )
})

export default CreateTabButton
