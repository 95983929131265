import React from "react"
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from "@chakra-ui/react"
import useSetTabContentMutation from "src/hooks/mutations/useSetTabContentMutation"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import useOriginalTabContent from "src/hooks/local/useOriginalTabContent"
import usePreviousTabContent from "src/hooks/local/usePreviousTabContent"
import { EVENTS } from "src/constants/analytics"

const Restore: React.FC = () => {
  const originalTabContent = useOriginalTabContent()
  const previousTabContent = usePreviousTabContent()
  const { analytics } = useFirebaseContext()
  const setTabContentMutation = useSetTabContentMutation()

  const onMenuButtonClick = () => {
    analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, {
      name: "restore",
    })
  }

  const onOriginalClick = async () => {
    if (typeof originalTabContent?.data === "undefined") return

    analytics.logEvent(EVENTS.RESTORE_TAB_CONTENT, { type: "original" })

    await setTabContentMutation.mutateAsync({
      id: originalTabContent.id,
      data: originalTabContent.data,
    })
  }

  const onPreviousClick = async () => {
    if (typeof previousTabContent?.data === "undefined") return

    analytics.logEvent(EVENTS.RESTORE_TAB_CONTENT, { type: "previous" })

    await setTabContentMutation.mutateAsync({
      id: previousTabContent.id,
      data: previousTabContent.data,
    })
  }

  const isContentSameAsOriginal =
    typeof originalTabContent?.isCurrent !== "boolean" ||
    originalTabContent.isCurrent
  const isContentSameAsPrevious =
    typeof previousTabContent?.isCurrent !== "boolean" ||
    previousTabContent.isCurrent

  return !isContentSameAsOriginal || !isContentSameAsPrevious ? (
    <Menu placement="bottom">
      <MenuButton
        as={Button}
        size="xs"
        variant="ghost"
        onClick={onMenuButtonClick}
      >
        Restore
      </MenuButton>
      <Portal>
        <MenuList minWidth={200}>
          <MenuItem
            isDisabled={isContentSameAsOriginal}
            onClick={onOriginalClick}
          >
            Original
          </MenuItem>
          <MenuItem
            isDisabled={isContentSameAsPrevious}
            onClick={onPreviousClick}
          >
            Previous
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  ) : null
}

export default Restore
