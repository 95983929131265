import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import { Workspace } from "src/types"
import { v4 as uuid } from "uuid"
import { last } from "lodash"
import React from "react"
import { workspaceWithTabCreated } from "src/utils/workspaceUtils"

const useCreateTabMutation = (workspace: Workspace) => {
  const queryClient = useQueryClient()
  const db = useDb()
  const newWorkspaceRef = React.useRef<Workspace>()

  return useMutation(
    async () => {
      const workspace: any = newWorkspaceRef.current
      const newTab: any = last(workspace.tabs)

      await Promise.all([
        db.workspace.put(workspace),
        db.tabContent.put({ id: newTab?.id, data: "" }),
      ])
    },
    {
      onMutate: (params: { tabName: string }) => {
        newWorkspaceRef.current = workspaceWithTabCreated(
          workspace,
          uuid(),
          params.tabName
        )
        queryClient.setQueryData(
          `workspace:${workspace.id}`,
          newWorkspaceRef.current
        )
      },
    }
  )
}

export default useCreateTabMutation
