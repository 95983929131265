import React from "react"
import {
  Button,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import DownloadForm from "src/components/forms/DownloadForm"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"
import { getActiveTab } from "src/utils/workspaceUtils"

const Download: React.FC = () => {
  const { workspace } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const firstFieldRef = React.useRef<HTMLInputElement | null>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const { onClose, onOpen } = React.useMemo(
    () => ({ onClose: () => setIsOpen(false), onOpen: () => setIsOpen(true) }),
    [setIsOpen]
  )

  const tabName = React.useMemo(() => getActiveTab(workspace)?.name, [
    workspace,
  ])

  return (
    <Popover
      initialFocusRef={firstFieldRef}
      placement="bottom"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Button
          size="xs"
          variant="ghost"
          onClick={() => {
            analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, {
              name: "download",
            })
            onOpen()
          }}
        >
          Download
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex={4} p={5}>
          <PopoverCloseButton />
          <DownloadForm
            firstFieldRef={firstFieldRef}
            onClose={onClose}
            defaultValues={{ fileName: tabName }}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default Download
