import React from "react"
import { Flex, Box, useColorModeValue } from "@chakra-ui/react"
import Property from "src/components/workspace/viewer/object/Property"
import { calcMarginLeft } from "src/utils/viewerUtils"
import { PropertyName } from "src/components/workspace/viewer/Viewer"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

type ObjectViewerProps = {
  value: any
  propertyName?: PropertyName
  level?: number
  parentBuildPathToField(propNames: PropertyName[]): void
}

const ObjectViewer: React.FC<ObjectViewerProps> = ({
  value,
  propertyName,
  level = 0,
  parentBuildPathToField,
}) => {
  const hoverColor = useColorModeValue("#8A91991A", "#191E2A")
  const borderColor = useColorModeValue("gray.500", "whiteAlpha.600")
  const keyColor = useColorModeValue("#026c6d", "#66e5ff")
  const [isExpanded, setIsExpanded] = React.useState(level === 0)
  const { analytics } = useFirebaseContext()

  const isArray = Array.isArray(value)

  const buildPathToField = React.useCallback(
    (propNames: PropertyName[]) => {
      if (typeof propertyName !== "undefined") propNames.push(propertyName)
      parentBuildPathToField(propNames)
    },
    [parentBuildPathToField, propertyName]
  )

  const onToggleExpanded = React.useCallback(() => {
    analytics.logEvent(EVENTS.VIEWER_ROW_CLICK, { type: "object" })
    setIsExpanded(!isExpanded)
    buildPathToField([])
  }, [isExpanded, setIsExpanded, buildPathToField, analytics])

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<any>) => {
      if (["Enter", " "].includes(event.key)) onToggleExpanded()
    },
    [onToggleExpanded]
  )

  return (
    <React.Fragment>
      <Flex
        direction="row"
        height={6}
        align="center"
        tabIndex={0}
        cursor="pointer"
        _hover={{ backgroundColor: hoverColor }}
        onClick={onToggleExpanded}
        onKeyDown={onKeyDown}
      >
        <Box
          fontFamily="mono"
          fontSize={14}
          ml={calcMarginLeft(level)}
          position="relative"
          sx={{
            ":before": {
              content: '""',
              position: "absolute",
              width: 0,
              height: 0,
              ...(isExpanded
                ? {
                    borderLeft: "6px solid transparent",
                    borderRight: "6px solid transparent",
                    borderTop: "9px solid",
                    borderTopColor: borderColor,
                    top: "6px",
                    left: "-20px",
                  }
                : {
                    borderTop: "6px solid transparent",
                    borderBottom: "6px solid transparent",
                    borderLeft: "9px solid",
                    borderLeftColor: borderColor,
                    top: "4px",
                    left: "-18px",
                  }),
            },
          }}
        >
          {typeof propertyName !== "undefined" && (
            <Box as="span" mr={2}>
              <Box as="span" color={keyColor}>
                {propertyName}
              </Box>
              {":"}
            </Box>
          )}
          {isArray ? `[${value.length}]` : `{${Object.keys(value).length}}`}
        </Box>
      </Flex>
      <Box display={isExpanded ? undefined : "none"}>
        {isArray
          ? value.map((value: any, idx: number) => (
              <Property
                key={idx.toString()}
                name={idx}
                value={value}
                level={level + 1}
                parentBuildPathToField={buildPathToField}
              />
            ))
          : Object.entries(value).map(([name, value]) => (
              <Property
                key={name}
                name={name}
                value={value}
                level={level + 1}
                parentBuildPathToField={buildPathToField}
              />
            ))}
      </Box>
    </React.Fragment>
  )
}

export default ObjectViewer
