import React from "react"
import { Flex } from "@chakra-ui/react"
import TabName from "src/components/workspace/controls/TabName"
import Mode from "src/components/workspace/controls/Mode"
import Sort from "src/components/workspace/controls/Sort"
import LoadData from "src/components/workspace/controls/LoadData"
import Download from "src/components/workspace/controls/Download"
import Copy from "src/components/workspace/controls/Copy"
import Minify from "src/components/workspace/controls/Minify"
import Serialize from "src/components/workspace/controls/Serialize"
import Beautify from "src/components/workspace/controls/Beautify"
import Validate from "src/components/workspace/controls/Validate"
import Restore from "src/components/workspace/controls/Restore"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"

const Controls: React.FC = () => {
  const { workspace } = useWorkspaceContext()

  return (
    <Flex direction="row" id={`controls-${workspace.id}`}>
      <Flex direction="row" mb={1} flexWrap="wrap" position="relative">
        <TabName />
        <Mode />
        <LoadData />
        <Download />
        <Sort />
        <Validate />
        <Minify />
        <Serialize />
        <Beautify />
        <Restore />
      </Flex>
      <Flex direction="row" ml="auto" mr={1}>
        <Copy />
      </Flex>
    </Flex>
  )
}

export default Controls
