export const EVENTS = Object.freeze({
  GITHUB_LINK_CLICK: "github_link_click",
  HEADER_SEARCH_SELECT: "header_search_select",
  HEADER_SETTINGS_OPEN: "header_settings_open",
  INFO_SECTION_VIEW: "info_section_view",
  LINKEDIN_LINK_CLICK: "linkedin_link_click",
  PATH_COPY: "path_copy",
  PRIVACY_POLICY_OPEN: "privacy_policy_open",
  RESTORE_TAB_CONTENT: "restore_tab_content",
  SPLIT_PANE_DRAG: "split_pane_drag",
  TAB_ACTIVATE_CLICK: "tab_activate_click",
  TAB_CREATE: "tab_create",
  TAB_CLOSE: "tab_close",
  TAB_LIST_SCROLL_ARROW_CLICK: "tab_list_scroll_arrow_click",
  TAG_DRAG_AND_DROP: "tag_drag_and_drop",
  UPDATE_VERSION: "update_version",
  VIEWER_PATH_CLICK: "viewer_path_click",
  VIEWER_ROW_CLICK: "viewer_row_click",
  WORKSPACE_CONTROL_CLICK: "workspace_control_click",
  WORKSPACE_DOWNLOAD_SUBMIT: "workspace_download_submit",
  WORKSPACE_FILE_UPLOAD: "workspace_file_upload",
  WORKSPACE_KEY_NAME_SORT: "workspace_key_name_sort",
  WORKSPACE_MODE_TOGGLE: "workspace_mode_toggle",
  WORKSPACE_TAB_NAME_UPDATE: "workspace_tab_name_update",
  WORKSPACE_URL_LOAD_FAIL: "workspace_url_load_fail",
  WORKSPACE_URL_LOAD_SUBMIT: "workspace_url_load_submit",
  WORKSPACE_URL_LOAD_SUCCESS: "workspace_url_load_success",
})
