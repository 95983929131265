import { FaDatabase, FaSearch, FaDownload } from "react-icons/fa"
import { RiTerminalWindowFill, RiWifiOffLine } from "react-icons/ri"
import { ImMagicWand } from "react-icons/im"
import { MdTab } from "react-icons/md"
import { BsEyeFill } from "react-icons/bs"
import { VscSettings } from "react-icons/vsc"

export const cards = Object.freeze([
  {
    icon: MdTab,
    title: "Tabs",
    description:
      "Work with multiple JSON documents at a time by leveraging tabs for an IDE-like experience within the browser.",
  },
  {
    icon: FaDatabase,
    title: "Store",
    description:
      "Persist your tabs between sessions using browser storage. Rest assured that your data will not be stored in the cloud.",
  },
  {
    icon: RiWifiOffLine,
    title: "Work Offline",
    description:
      "Spotty internet connection? We've got you covered. Offline compatability means nothing can keep you from your JSON.",
  },
  {
    icon: RiTerminalWindowFill,
    title: "Edit",
    description:
      "Modern times call for a modern editor: complete with syntax highlighting, linting, and code completion.",
  },
  {
    icon: ImMagicWand,
    title: "Beautify",
    description:
      "Format your documents instantly with helpful repairs such as nested serialization, stripping comments and trailing commas, adding missing quotes/commas, and much more.",
  },
  {
    icon: BsEyeFill,
    title: "Visualize",
    description:
      "View complex data sets in a tree viewer that’s easy on the eyes and simple to navigate.",
  },
  {
    icon: VscSettings,
    title: "Customize",
    description:
      "Personalize your experience with horizontal/vertical split screen mode, light/dark mode, VIM mode, and more.",
  },
  {
    icon: FaDownload,
    title: "Load Data",
    description:
      "Streamlined data access with support for loading JSON from a URL and uploading/downloading files from your computer.",
  },
  {
    icon: FaSearch,
    title: "Search",
    description:
      "Discover and switch between tabs efficiently with a lightning fast search interface.",
  },
])

export const cardsOneColumn = Object.freeze([cards])

export const cardsTwoColumn = Object.freeze([
  cards.filter((_, i) => i % 2 === 0),
  cards.filter((_, i) => i % 2 !== 0),
])

export const cardsThreeColumn = Object.freeze([
  cards.filter((_, i) => [0, 3, 6].includes(i)),
  cards.filter((_, i) => [1, 4, 7].includes(i)),
  cards.filter((_, i) => [2, 5, 8].includes(i)),
])
