import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"

const useSetSettingMutation = (id: string) => {
  const queryClient = useQueryClient()
  const db = useDb()

  return useMutation(
    async (value: string) => {
      await db.setting.put({ id, value })
    },
    {
      onMutate: (value) => {
        queryClient.setQueryData(`setting:${id}`, { id, value })
      },
    }
  )
}

export default useSetSettingMutation
