import { Button, useToast } from "@chakra-ui/react"
import React from "react"
import parseJson from "json-parse-better-errors"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import useTabContent from "src/hooks/data/useTabContent"
import { EVENTS } from "src/constants/analytics"

const Validate: React.FC = () => {
  const toast = useToast()

  const { workspace, activeTab } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const { data } = tabContent ?? {}

  const onClick = () => {
    analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, { name: "validate" })

    if (typeof data === "undefined") return

    try {
      parseJson(data)

      toast({
        title: "Valid JSON",
        duration: 3000,
        status: "success",
        isClosable: true,
        position: "bottom",
      })
    } catch (error) {
      toast({
        title: "Invalid JSON",
        description: error.message,
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "bottom",
      })
    }
  }

  return (
    <Button
      size="xs"
      variant="ghost"
      isDisabled={activeTab?.mode !== "text"}
      onClick={onClick}
    >
      Validate
    </Button>
  )
}

export default Validate
