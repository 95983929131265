import React from "react"
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import { lightTheme, darkTheme } from "src/theme/monacoThemes"

const useMonacoThemes = () => {
  React.useEffect(() => {
    monaco.editor.defineTheme("lightCustom", lightTheme)
    monaco.editor.defineTheme("darkCustom", darkTheme)
  }, [])
}

export default useMonacoThemes
