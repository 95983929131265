import debounce from "debounce-promise"
import React from "react"
import useSetTabContentMutation from "src/hooks/mutations/useSetTabContentMutation"

export const DEBOUNCE_MS = 250

const useOnEditorChange = (tabId: string) => {
  const setTabContentMutation = useSetTabContentMutation()
  const debouncedSetTab = React.useMemo(
    () => debounce(setTabContentMutation.mutateAsync, DEBOUNCE_MS),
    [setTabContentMutation.mutateAsync]
  )

  return React.useCallback(
    (value: string) => {
      debouncedSetTab({ id: tabId, data: value })
    },
    [tabId, debouncedSetTab]
  )
}

export default useOnEditorChange
