import React from "react"
import { Box, Divider, Flex, useColorModeValue } from "@chakra-ui/react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import Tab from "src/components/workspace/tabs/Tab"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import CreateTab from "src/components/workspace/tabs/createTab/CreateTab"
import ScrollArrow from "src/components/workspace/tabs/ScrollArrow"
import useResizeObserver from "use-resize-observer"

const TabList: React.FC = () => {
  const backgroundColor = useColorModeValue("gray.200", undefined)
  const bottomDividerStyles = useColorModeValue({ borderColor: "white" }, {})
  const { workspace } = useWorkspaceContext()
  const scrollableRef = React.useRef<HTMLDivElement | null>(null)
  const hasRenderedOnceRef = React.useRef(false)
  const [isScrollable, setIsScrollable] = React.useState(false)

  const checkIsScrollable = React.useCallback(() => {
    const refIsScrollable =
      !!scrollableRef.current &&
      scrollableRef.current.scrollWidth > scrollableRef.current.clientWidth

    const shouldUpdateState = refIsScrollable !== isScrollable

    /* istanbul ignore next */
    if (shouldUpdateState) {
      setIsScrollable(refIsScrollable)
    }
  }, [isScrollable, setIsScrollable])

  const { ref: listContainerRef } = useResizeObserver<HTMLDivElement>({
    onResize: checkIsScrollable,
  })

  const scrollLeft = React.useCallback(() => {
    /* istanbul ignore next */
    scrollableRef?.current?.scrollTo({
      left: Math.max(0, scrollableRef.current.scrollLeft - 50),
      behavior: "smooth",
    })
  }, [])

  const scrollRight = React.useCallback(() => {
    /* istanbul ignore next */
    scrollableRef?.current?.scrollTo({
      left: Math.min(
        scrollableRef.current.scrollWidth,
        scrollableRef.current.scrollLeft + 50
      ),
      behavior: "smooth",
    })
  }, [])

  React.useEffect(() => {
    hasRenderedOnceRef.current = true
  }, [])

  React.useEffect(() => {
    checkIsScrollable()
  }, [checkIsScrollable, workspace.tabs.length])

  return (
    <Box ref={listContainerRef}>
      <Droppable
        droppableId={workspace.id.toString()}
        direction="horizontal"
        isCombineEnabled={true}
      >
        {(dropProvided) => (
          <Flex
            direction="row"
            alignItems="center"
            paddingX={1}
            borderTopLeftRadius="0.25rem"
            borderTopRightRadius="0.25rem"
            id={`tab-list-${workspace.id}`}
            backgroundColor={backgroundColor}
          >
            {
              /* istanbul ignore next */
              isScrollable && (
                <ScrollArrow direction="left" scroll={scrollLeft} />
              )
            }
            <Flex
              direction="row"
              ref={(ref) => {
                scrollableRef.current = ref
                return dropProvided.innerRef(ref)
              }}
              overflowX="auto"
              pt={1}
              sx={{
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              role="tablist"
            >
              {workspace.tabs.map((tab, idx) => (
                <Draggable key={tab.id} draggableId={tab.id} index={idx}>
                  {(dragProvided, dragSnapshot) => (
                    <Tab
                      dragProvided={dragProvided}
                      dragSnapshot={dragSnapshot}
                      tab={tab}
                      parentHasRenderedOnceRef={hasRenderedOnceRef}
                    />
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </Flex>
            {
              /* istanbul ignore next */
              isScrollable && (
                <ScrollArrow direction="right" scroll={scrollRight} />
              )
            }
            <CreateTab />
          </Flex>
        )}
      </Droppable>
      <Divider
        orientation="horizontal"
        mt={0}
        mb={1}
        {...bottomDividerStyles}
      />
    </Box>
  )
}

export default TabList
