import React from "react"
import { Stack } from "@chakra-ui/react"

const CardHeading: React.FC = ({ children }) => {
  return (
    <Stack direction="row" align="center">
      {children}
    </Stack>
  )
}

export default CardHeading
