import React from "react"
import ObjectViewer from "src/components/workspace/viewer/object/ObjectViewer"
import PrimitiveViewer from "src/components/workspace/viewer/primitive/PrimitiveViewer"
import { PropertyName } from "src/components/workspace/viewer/Viewer"

type PropertyProps = {
  name: PropertyName
  value: any
  level: number
  parentBuildPathToField(propNames: PropertyName[]): void
}

const Property: React.FC<PropertyProps> = React.memo(
  ({ name, value, level, parentBuildPathToField }) => {
    if (typeof value === "object" && value) {
      return (
        <ObjectViewer
          value={value}
          propertyName={name}
          level={level}
          parentBuildPathToField={parentBuildPathToField}
        />
      )
    }

    return (
      <PrimitiveViewer
        value={value}
        propertyName={name}
        level={level}
        parentBuildPathToField={parentBuildPathToField}
      />
    )
  }
)

export default Property
