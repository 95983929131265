import React from "react"
import CreateTabButton from "src/components/workspace/tabs/createTab/CreateTabButton"
import CreateTabPopover from "src/components/workspace/tabs/createTab/CreateTabPopover"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import { useSettingsContext } from "src/context/SettingsProvider"
import { useTabsContext } from "src/context/TabsProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const CreateTabImmediate: React.FC = () => {
  const { isNewTabFormEnabled } = useSettingsContext()
  const { createTab } = useWorkspaceContext()
  const { newTabDefaultName } = useTabsContext()
  const { analytics } = useFirebaseContext()
  const onCreateTabButtonClick = async () => {
    await createTab({ tabName: newTabDefaultName })
    analytics.logEvent(EVENTS.TAB_CREATE, { custom_name_provided: false })
  }

  return isNewTabFormEnabled ? (
    <CreateTabPopover />
  ) : (
    <CreateTabButton onClick={onCreateTabButtonClick} />
  )
}

export default CreateTabImmediate
