import { useQuery } from "react-query"
import { useDb } from "src/context/DbProvider"

const useSettingQuery = (id: string) => {
  const db = useDb()

  return useQuery(`setting:${id}`, async () => {
    const setting = await db.setting.get(id)
    return setting
  })
}

export default useSettingQuery
