import React from "react"
import { Button, Tooltip, useClipboard } from "@chakra-ui/react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useTabContent from "src/hooks/data/useTabContent"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const Copy: React.FC = () => {
  const { workspace } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const { onCopy, hasCopied } = useClipboard(tabContent?.data ?? "")

  return (
    <Tooltip label="Copied" placement="top" isOpen={hasCopied} fontSize="xs">
      <Button
        size="xs"
        onClick={() => {
          analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, { name: "copy" })
          onCopy()
        }}
      >
        Copy
      </Button>
    </Tooltip>
  )
}

export default Copy
