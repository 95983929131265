import { NEW_TAB_DEFAULTS } from "src/constants"
import { Workspace } from "src/types"
import { v4 as uuid } from "uuid"

export const workspaceWithTabCreated = (
  workspace: Workspace,
  tabId: string,
  tabName: string
): Workspace => ({
  ...workspace,
  activeTabId: tabId,
  tabs: [
    ...workspace.tabs,
    {
      ...NEW_TAB_DEFAULTS,
      id: tabId,
      name: tabName,
    },
  ],
})

export const workspaceWithTabClosed = (
  workspace: Workspace,
  tabId: string,
  newTabDefaultName: string
): Workspace => {
  const newTabs = Array.from(workspace.tabs)
  const tabIdx = newTabs.findIndex((tab) => tab.id === tabId)
  newTabs.splice(tabIdx, 1)

  if (!newTabs.length) {
    newTabs.push({ id: uuid(), name: newTabDefaultName, ...NEW_TAB_DEFAULTS })
  }

  const isActiveTabClosed = workspace.activeTabId === tabId
  const newActiveTabId = isActiveTabClosed
    ? newTabs[Math.min(tabIdx, newTabs.length - 1)].id
    : workspace.activeTabId

  return {
    ...workspace,
    activeTabId: newActiveTabId,
    tabs: newTabs,
  }
}

export const getActiveTab = (workspace: Workspace) =>
  workspace.tabs.find((tab) => tab.id === workspace.activeTabId)
