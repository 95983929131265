import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import { TabContent } from "src/types"

const useSetTabContentMutation = () => {
  const db = useDb()
  const queryClient = useQueryClient()

  return useMutation(
    async (tabContent: TabContent) => {
      await db.tabContent.put(tabContent)
    },
    {
      onMutate: (tabContent: TabContent) => {
        queryClient.setQueryData(`tab_content:${tabContent.id}`, tabContent)
      },
    }
  )
}

export default useSetTabContentMutation
