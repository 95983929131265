import React from "react"
import { Stack, Button, FormControl, FormLabel, Input } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { cleanWhitespace } from "src/utils/stringUtils"
import usePrimaryColorScheme from "src/hooks/theme/usePrimaryColorScheme"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import { useTabsContext } from "src/context/TabsProvider"

type FormProps = {
  firstFieldRef: React.MutableRefObject<HTMLInputElement | null>
  buttonText: string
  placeholder?: string
  tabId?: string
  defaultValues?: FormData
  onClose(): void
  onSubmitSuccess?(): void
  submitMutation(params: { tabName: string; tabId?: string }): Promise<void>
}

type FormData = {
  tabName?: string
}

const TabNameForm: React.FC<FormProps> = ({
  firstFieldRef,
  onClose,
  buttonText,
  placeholder,
  tabId,
  onSubmitSuccess,
  submitMutation,
  defaultValues,
}) => {
  const { workspace } = useWorkspaceContext()
  const { newTabDefaultName } = useTabsContext()
  const primaryColorScheme = usePrimaryColorScheme()
  const { register, handleSubmit, reset } = useForm<FormData>({ defaultValues })
  const isCreateNewTab = typeof tabId === "undefined"
  const inputId = !isCreateNewTab
    ? `tabName-${tabId}`
    : `tabName-${workspace.id}`

  const onSubmit = handleSubmit(({ tabName }) => {
    const cleaned = cleanWhitespace(tabName)
    const newTabName =
      !isCreateNewTab || cleaned.length ? cleaned : newTabDefaultName
    submitMutation({ tabName: newTabName, tabId })
      .then(() => {
        if (typeof onSubmitSuccess !== "undefined") onSubmitSuccess()
      })
      .finally(() => onClose())
  })

  React.useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={4} direction="column">
        <FormControl>
          <FormLabel htmlFor={inputId}>Tab name</FormLabel>
          <Input
            ref={(ref: any) => {
              firstFieldRef.current = ref
              register(ref)
            }}
            id={inputId}
            placeholder={placeholder}
            name="tabName"
          />
        </FormControl>
        <Button colorScheme={primaryColorScheme} type="submit">
          {buttonText}
        </Button>
      </Stack>
    </form>
  )
}

export default TabNameForm
