import React from "react"
import { useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import defaultSettings from "src/defaultSettings"

const useDefaultSettings = () => {
  const queryClient = useQueryClient()
  const db = useDb()

  React.useEffect(() => {
    const addDefaultSettings = async () => {
      try {
        await db.setting.bulkAdd(
          defaultSettings.map(({ id, value }) => ({
            id,
            value: value.toString(),
          }))
        )
      } catch (e) {
        // Must catch to ignore failures that occur as a result of the item
        // already existing in the DB
      } finally {
        queryClient.invalidateQueries(
          defaultSettings.map((s) => `setting:${s.id}`)
        )
      }
    }

    addDefaultSettings()
  }, [db, queryClient])
}

export default useDefaultSettings
