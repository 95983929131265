import { Flex, useColorMode } from "@chakra-ui/react"
import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

type ScrollArrowProps = {
  direction: "left" | "right"
  scroll(): void
}

const ScrollArrow: React.FC<ScrollArrowProps> = ({ direction, scroll }) => {
  const { colorMode } = useColorMode()
  const { analytics } = useFirebaseContext()

  const [isScrolling, setIsScrolling] = React.useState(false)

  const onStartScrolling = React.useCallback(() => {
    if (!isScrolling) setIsScrolling(true)
  }, [isScrolling, setIsScrolling])

  const onStopScrolling = React.useCallback(() => {
    if (isScrolling) {
      setIsScrolling(false)
      analytics.logEvent(EVENTS.TAB_LIST_SCROLL_ARROW_CLICK, { direction })
    }
  }, [isScrolling, setIsScrolling, analytics, direction])

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<any>) => {
      if (["Enter", " "].includes(event.key)) onStartScrolling()
    },
    [onStartScrolling]
  )

  React.useEffect(() => {
    if (!isScrolling) return

    const interval = setInterval(() => {
      scroll()
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [isScrolling, scroll])

  React.useEffect(() => {
    const onDocumentMouseUp = () => {
      onStopScrolling()
    }

    document.addEventListener("mouseup", onDocumentMouseUp)

    return () => {
      document.removeEventListener("mouseup", onDocumentMouseUp)
    }
  }, [onStopScrolling])

  return (
    <Flex
      as="button"
      aria-label={`Scroll tab list ${direction}`}
      w="1.3125rem"
      justify="center"
      align="center"
      alignSelf="stretch"
      mt={1}
      cursor="pointer"
      sx={{
        ...(direction === "left"
          ? {
              borderRight: "1px solid",
              borderRightColor:
                colorMode === "light" ? "gray.400" : "whiteAlpha.300",
              borderTopLeftRadius: "0.25rem",
              borderBottomLeftRadius: "0.25rem",
            }
          : {
              borderLeft: "1px solid",
              borderLeftColor:
                colorMode === "light" ? "gray.400" : "whiteAlpha.300",
              borderTopRightRadius: "0.25rem",
              borderBottomRightRadius: "0.25rem",
            }),
        ":hover": {
          backgroundColor:
            colorMode === "light" ? "gray.300" : "whiteAlpha.200",
        },
        ":active": {
          backgroundColor:
            colorMode === "light" ? "gray.400" : "whiteAlpha.300",
        },
      }}
      onMouseDown={onStartScrolling}
      onMouseUp={onStopScrolling}
      onKeyDown={onKeyDown}
      onKeyUp={onStopScrolling}
    >
      {direction === "left" ? (
        <ChevronLeftIcon h="20px" w="20px" />
      ) : (
        <ChevronRightIcon h="20px" w="20px" />
      )}
    </Flex>
  )
}

export default ScrollArrow
