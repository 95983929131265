import {
  Box,
  Button,
  Center,
  CloseButton,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"
import { TooltipRenderProps } from "react-joyride"
import usePrimaryColor from "src/hooks/theme/usePrimaryColor"
import { steps } from "src/components/layout/Tour"

const CustomTooltip: React.FC<TooltipRenderProps> = ({
  step,
  index,
  closeProps,
  primaryProps,
  tooltipProps,
}) => {
  const backgroundColor = useColorModeValue("#fff", "gray.700")
  const primaryColor = usePrimaryColor()
  const buttonColorScheme = useColorModeValue("lightPrimary", undefined)
  const badgeTextColor = useColorModeValue("#fff", "gray.800")

  return (
    <Box
      {...tooltipProps}
      width="20rem"
      backgroundColor={backgroundColor}
      borderRadius="md"
      p={5}
      position="relative"
    >
      <CloseButton
        {...closeProps}
        position="absolute"
        top={1}
        right={2}
        size="sm"
      />
      <Center
        position="absolute"
        top="-1rem"
        left="-1rem"
        color={badgeTextColor}
        backgroundColor={primaryColor}
        borderRadius="50%"
        fontSize="0.85rem"
        height="2rem"
        width="2rem"
        fontWeight="bold"
      >
        {index + 1}/{steps.length}
      </Center>
      <Stack>
        {step.title && (
          <Heading as="h3" fontSize="lg" lineHeight="lg">
            {step.title}
          </Heading>
        )}
        <Text>{step.content}</Text>
        <Button {...primaryProps} colorScheme={buttonColorScheme}>
          {index < steps.length - 1 ? "Next" : "Close"}
        </Button>
      </Stack>
    </Box>
  )
}

export default CustomTooltip
