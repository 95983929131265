import { Box, Flex, useColorModeValue } from "@chakra-ui/react"
import React from "react"
import { calcMarginLeft } from "src/utils/viewerUtils"
import { PropertyName } from "src/components/workspace/viewer/Viewer"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

type PrimitiveViewerProps = {
  value: string | number | boolean
  propertyName: PropertyName
  level: number
  parentBuildPathToField(propNames: PropertyName[]): void
}

const escapeCharMap = new Map([
  ["\\", "\\\\"],
  ['"', '\\"'],
  ["\b", "\\b"],
  ["\f", "\\f"],
  ["\n", "\\n"],
  ["\r", "\\r"],
  ["\t", "\\t"],
  ["\v", "\\v"],
])

const PrimitiveViewer: React.FC<PrimitiveViewerProps> = ({
  value,
  propertyName,
  level,
  parentBuildPathToField,
}) => {
  const stringColor = useColorModeValue("#c24c44", "#cfff8c")
  const boolAndNumberColor = useColorModeValue("#3c6dd6", "#ffcc66")
  const hoverColor = useColorModeValue("#8A91991A", "#191E2A")
  const keyColor = useColorModeValue("#026c6d", "#66e5ff")
  const { analytics } = useFirebaseContext()

  const buildPathToField = React.useCallback(
    (propNames: PropertyName[]) => {
      propNames.push(propertyName)
      parentBuildPathToField(propNames)
    },
    [parentBuildPathToField, propertyName]
  )

  const onClick = React.useCallback(() => {
    analytics.logEvent(EVENTS.VIEWER_ROW_CLICK, { type: "primitive" })
    buildPathToField([])
  }, [buildPathToField, analytics])

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<any>) => {
      if (["Enter", " "].includes(event.key)) onClick()
    },
    [onClick]
  )

  const renderedValue = React.useMemo(() => {
    if (typeof value === "string" && value !== null) {
      return (
        <Box
          color={stringColor}
          whiteSpace="break-spaces"
          wordBreak="break-all"
        >
          {value
            .split("")
            .map((c) => escapeCharMap.get(c) ?? c)
            .join("")}
        </Box>
      )
    }

    return (
      <Box as="span" color={boolAndNumberColor}>
        {value?.toString() ?? "null"}
      </Box>
    )
  }, [value, stringColor, boolAndNumberColor])

  return (
    <Flex
      fontFamily="mono"
      fontSize={14}
      pl={calcMarginLeft(level)}
      direction="row"
      tabIndex={0}
      cursor="pointer"
      _hover={{ backgroundColor: hoverColor }}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <Flex direction="row" mr={2}>
        <Box as="span" color={keyColor}>
          {propertyName}
        </Box>
        {":"}
      </Flex>
      {renderedValue}
    </Flex>
  )
}

export default PrimitiveViewer
