import React from "react"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { getBoolValueFromSetting } from "src/utils/settingUtils"
import useSetting from "src/hooks/settings/useSetting"

const useBoolSetting = (settingId: string) => {
  const [setting, setSetting] = useSetting(settingId)
  const { analytics } = useFirebaseContext()
  const hasSetPropertyOnceRef = React.useRef(false)

  React.useEffect(() => {
    const value = getBoolValueFromSetting(setting)
    if (typeof value !== "undefined") {
      if (hasSetPropertyOnceRef.current) {
        analytics.logEvent(`${settingId}_change`, { value })
      }
      analytics.setUserProperties({ [settingId]: value })
      hasSetPropertyOnceRef.current = true
    }
  }, [analytics, setting, settingId])

  return React.useMemo<
    [boolean | undefined, (value: boolean) => Promise<void>]
  >(
    () => [
      getBoolValueFromSetting(setting),
      async (value: boolean) => {
        await setSetting(value.toString())
      },
    ],
    [setting, setSetting]
  )
}

export default useBoolSetting
