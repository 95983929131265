import React from "react"
import { DropResult } from "react-beautiful-dnd"
import { Workspace } from "src/types"
import useReorderTabsMutation from "src/hooks/mutations/useReorderTabsMutation"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const useOnDragEnd = (workspaces: Workspace[] | undefined) => {
  const reorderTabsMutation = useReorderTabsMutation()
  const { analytics } = useFirebaseContext()

  return React.useCallback(
    ({ source, destination }: DropResult) => {
      if (
        destination &&
        workspaces &&
        (destination.droppableId !== source.droppableId ||
          destination.index !== source.index)
      ) {
        reorderTabsMutation
          .mutateAsync({ workspaces, source, destination })
          .then(() =>
            analytics.logEvent(EVENTS.TAG_DRAG_AND_DROP, {
              workspace_from_id: source.index,
              workspace_to_id: destination.index,
            })
          )
      }
    },
    [reorderTabsMutation, workspaces, analytics]
  )
}

export default useOnDragEnd
