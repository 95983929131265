import { theme as defaultTheme, extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"
import "fontsource-fira-code/400-normal.css"

export const mainFontFamily = [
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Helvetica Neue"',
  "Helvetica",
  "Arial",
  "sans-serif",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
].join()

export const lightPrimaryPalette = {
  50: "#e7f3f4",
  100: "#c3e0e4",
  200: "#9bcbd3",
  300: "#73b6c1",
  400: "#55a7b3",
  500: "#3797a6",
  600: "#318f9e",
  700: "#2a8495",
  800: "#237a8b",
  900: "#16697b",
}

export const darkPrimaryPalette = {
  50: "#e8fff5",
  100: "#c7ffe6",
  200: "#a1ffd5",
  300: "#7bffc4",
  400: "#5fffb7",
  500: "#43ffaa",
  600: "#3dffa3",
  700: "#34ff99",
  800: "#2cff90",
  900: "#1eff7f",
}

export const globalStyleFunction = (props: any) => ({
  "html, body": {
    backgroundColor: mode("white", "gray.800")(props),
  },
  ".gutter": {
    transition: "background-color 250ms",
    "&:hover, &:focus": {
      backgroundColor: mode("lightPrimary.100", "gray.700")(props),
    },
    "&.gutter-horizontal": {
      cursor: "col-resize",
    },
    "&.gutter-vertical": {
      cursor: "row-resize",
    },
  },
})

const chakraTheme = extendTheme({
  fonts: {
    body: mainFontFamily,
    heading: mainFontFamily,
    mono: ['"Fira Code"', defaultTheme.fonts.mono].join(),
  },
  colors: {
    lightPrimary: lightPrimaryPalette,
    darkPrimary: darkPrimaryPalette,
  },
  styles: {
    global: globalStyleFunction,
  },
  useSystemColorMode: true,
})

export default chakraTheme
