import React from "react"
import {
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from "@chakra-ui/react"
import useSetTabContentMutation from "src/hooks/mutations/useSetTabContentMutation"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import { recursivePropertyNameSort } from "src/utils/sortUtils"
import useTabContent from "src/hooks/data/useTabContent"
import { parseJson } from "src/utils/stringUtils"
import { useSettingsContext } from "src/context/SettingsProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

const SortBy: React.FC = () => {
  const { workspace } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const toast = useToast()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const setTabContentMutation = useSetTabContentMutation()
  const { editorSpacing } = useSettingsContext()

  const onMenuButtonClick = () => {
    analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, { name: "sort" })
  }

  const onChange = React.useCallback(
    async (value: "asc" | "desc") => {
      analytics.logEvent(EVENTS.WORKSPACE_KEY_NAME_SORT, { order: value })

      if (
        typeof tabContent?.data === "undefined" ||
        typeof editorSpacing === "undefined"
      ) {
        return
      }

      try {
        const obj = parseJson(tabContent.data)

        await setTabContentMutation.mutateAsync({
          id: workspace.activeTabId,
          data: JSON.stringify(
            recursivePropertyNameSort(obj, value),
            null,
            editorSpacing
          ),
        })
      } catch (error) {
        toast({
          title: "Unable to parse JSON",
          description: error.message,
          duration: 3000,
          status: "error",
          isClosable: true,
          position: "bottom",
        })
      }
    },
    [
      setTabContentMutation,
      toast,
      tabContent,
      workspace,
      editorSpacing,
      analytics,
    ]
  )

  const onAscendingClick = () => onChange("asc")
  const onDescendingClick = () => onChange("desc")

  return (
    <Menu placement="bottom">
      <MenuButton
        as={Button}
        size="xs"
        variant="ghost"
        onClick={onMenuButtonClick}
      >
        Sort
      </MenuButton>
      <Portal>
        <MenuList minWidth={200}>
          <MenuGroup title="Sort by key names">
            <MenuItem onClick={onAscendingClick}>Ascending</MenuItem>
            <MenuItem onClick={onDescendingClick}>Descending</MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default SortBy
