import React from "react"
import {
  Stack,
  Button,
  InputRightAddon,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
} from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { cleanWhitespace } from "src/utils/stringUtils"
import fileDownload from "js-file-download"
import useTabContent from "src/hooks/data/useTabContent"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import usePrimaryColorScheme from "src/hooks/theme/usePrimaryColorScheme"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

type FormProps = {
  firstFieldRef: React.MutableRefObject<HTMLInputElement | null>
  defaultValues?: FormData
  onClose: () => void
}

type FormData = {
  fileName?: string
}

const DownloadForm: React.FC<FormProps> = React.memo(
  ({ firstFieldRef, onClose, defaultValues }) => {
    const { workspace } = useWorkspaceContext()
    const { analytics } = useFirebaseContext()
    const primaryColorScheme = usePrimaryColorScheme()
    const { register, handleSubmit, reset, errors } = useForm<FormData>({
      defaultValues,
    })
    const { tabContent } = useTabContent(workspace.activeTabId)
    const inputId = `fileName-${workspace.id}`

    const onSubmit = handleSubmit(({ fileName }) => {
      analytics.logEvent(EVENTS.WORKSPACE_DOWNLOAD_SUBMIT)
      fileDownload(tabContent?.data ?? "", `${cleanWhitespace(fileName)}.json`)
      onClose()
    })

    React.useEffect(() => {
      reset(defaultValues)
    }, [defaultValues, reset])

    return (
      <form onSubmit={onSubmit}>
        <Stack spacing={4} direction="column">
          <FormControl>
            <FormLabel htmlFor={inputId}>File name</FormLabel>
            <InputGroup>
              <Input
                ref={(ref: any) => {
                  firstFieldRef.current = ref
                  register(ref, {
                    validate: (val) => !!cleanWhitespace(val).length,
                  })
                }}
                id={inputId}
                name="fileName"
                width="13.125rem"
                isInvalid={!!errors.fileName}
              />
              <InputRightAddon children=".json" />
            </InputGroup>
          </FormControl>
          <Button colorScheme={primaryColorScheme} type="submit">
            Download
          </Button>
        </Stack>
      </form>
    )
  }
)

export default DownloadForm
