import React from "react"
import firebase from "firebase/app"

import "firebase/analytics"

type ContextValue = { analytics: firebase.analytics.Analytics } | undefined

const FirebaseContext = React.createContext<ContextValue>(undefined)

export const FirebaseProvider: React.FC = ({ children }) => {
  const value = React.useMemo<ContextValue>(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(
        JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG ?? "{}")
      )
    }

    const analytics = firebase.analytics()
    analytics.setUserProperties({ app_version: process.env.REACT_APP_VERSION })

    return { analytics }
  }, [])

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebaseContext = () => {
  const context = React.useContext(FirebaseContext)
  if (context === undefined) {
    throw new Error("useFirebaseContext must be used within a FirebaseProvider")
  }
  return context
}
