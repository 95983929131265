import React from "react"
import { useSettingsContext } from "src/context/SettingsProvider"
import HorizontalSplitPane from "src/components/layout/workspaces/HorizontalSplitPane"
import QuadSplitPane from "src/components/layout/workspaces/QuadSplitPane"
import SinglePane from "src/components/layout/workspaces/SinglePane"
import VerticalSplitPane from "src/components/layout/workspaces/VerticalSplitPane"

const Workspaces: React.FC = () => {
  const {
    isHorizontalSplitScreenEnabled,
    isVerticalSplitScreenEnabled,
  } = useSettingsContext()

  if (isHorizontalSplitScreenEnabled && isVerticalSplitScreenEnabled) {
    return <QuadSplitPane />
  }

  if (isHorizontalSplitScreenEnabled) {
    return <HorizontalSplitPane />
  }

  if (isVerticalSplitScreenEnabled) {
    return <VerticalSplitPane />
  }

  return <SinglePane />
}

export default Workspaces
