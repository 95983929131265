import React from "react"
import { Box, FormControl, FormLabel, Select } from "@chakra-ui/react"
import { useSettingsContext } from "src/context/SettingsProvider"

const ELEMENT_ID = "settings-tab-spacing"
const TAB_SIZES = Object.freeze([1, 2, 3, 4])

const EditorSpacing: React.FC = () => {
  const { editorSpacing, setEditorSpacing } = useSettingsContext()

  return typeof editorSpacing !== "undefined" ? (
    <FormControl>
      <FormLabel htmlFor={ELEMENT_ID}>Spacing</FormLabel>
      <Select
        id={ELEMENT_ID}
        value={editorSpacing}
        onChange={(event) => setEditorSpacing(+event.target.value)}
        data-testid="editor-spacing-select"
      >
        {TAB_SIZES.map((num) => (
          <Box as="option" key={num} value={num}>
            {num} Tab Space
          </Box>
        ))}
      </Select>
    </FormControl>
  ) : null
}

export default EditorSpacing
