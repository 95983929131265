import React from "react"
import { Center, Spinner } from "@chakra-ui/react"

const BodyLoader: React.FC = () => (
  <Center height="100%" width="100%" data-testid="body-loader">
    <Spinner size="xl" />
  </Center>
)

export default BodyLoader
