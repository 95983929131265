import React from "react"
import { Flex } from "@chakra-ui/react"
import TabList from "src/components/workspace/tabs/TabList"
import { Workspace } from "src/types"
import {
  useWorkspaceContext,
  WorkspaceProvider,
} from "src/context/WorkspaceProvider"
import Editor from "src/components/workspace/editor/Editor"
import Controls from "src/components/workspace/controls/Controls"
import Viewer from "src/components/workspace/viewer/Viewer"

const WorkspaceBody: React.FC = () => {
  const { workspace, activeTab } = useWorkspaceContext()

  return React.useMemo(
    () => (
      <Flex
        id={`workspace-${workspace.id}`}
        direction="column"
        height="100%"
        width="100%"
      >
        <TabList />
        <Controls />
        {activeTab?.mode === "text" ? <Editor /> : <Viewer />}
      </Flex>
    ),
    [workspace, activeTab]
  )
}

type WorkspaceProps = {
  workspace: Workspace
}

const WorkspaceComponent: React.FC<WorkspaceProps> = React.memo(
  ({ workspace }) => {
    return (
      <WorkspaceProvider workspace={workspace}>
        <WorkspaceBody />
      </WorkspaceProvider>
    )
  }
)

export default WorkspaceComponent
