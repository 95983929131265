import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { useDb } from "src/context/DbProvider"
import { Workspace } from "src/types"

const workspaceWithTabUpdated = (
  workspace: Workspace,
  tabId: string,
  tabName: string
): Workspace => ({
  ...workspace,
  tabs: workspace.tabs.map((tab) =>
    tab.id === tabId
      ? { ...tab, ...(tabName.length ? { name: tabName } : {}) }
      : tab
  ),
})

const useSetTabNameMutation = (workspace: Workspace) => {
  const queryClient = useQueryClient()
  const db = useDb()
  const newWorkspaceRef = React.useRef<Workspace>()

  return useMutation(
    async (params: { tabName: string; tabId: string }) => {
      const workspace: any = newWorkspaceRef.current
      await db.workspace.put(workspace)
    },
    {
      onMutate: ({ tabName, tabId }) => {
        newWorkspaceRef.current = workspaceWithTabUpdated(
          workspace,
          tabId,
          tabName
        )
        queryClient.setQueryData(
          `workspace:${workspace.id}`,
          newWorkspaceRef.current
        )
      },
    }
  )
}

export default useSetTabNameMutation
