import {
  Box,
  Heading,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"
import {
  Card,
  CardHeading,
  CardIcon,
  CardTitle,
  CardDescription,
} from "src/components/common/Card"
import { useInView } from "react-intersection-observer"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import {
  cardsOneColumn,
  cardsTwoColumn,
  cardsThreeColumn,
} from "src/components/layout/config/cards"
import { EVENTS } from "src/constants/analytics"

export const heading =
  "An intuitive JSON editor, visualizer, and formatter with first-class support for storing tabs of data in the browser"

const Info: React.FC = React.memo(() => {
  const { analytics } = useFirebaseContext()
  const backgroundColor = useColorModeValue("gray.100", "gray.900")
  const { ref, inView } = useInView({ triggerOnce: true })
  const cards = useBreakpointValue([
    cardsOneColumn,
    cardsOneColumn,
    cardsTwoColumn,
    cardsThreeColumn,
    cardsThreeColumn,
  ])

  React.useEffect(() => {
    if (inView) analytics.logEvent(EVENTS.INFO_SECTION_VIEW)
  }, [inView, analytics])

  return (
    <Box
      ref={ref}
      mt={[16, 20, 24, 32, 40]}
      py={32}
      px={6}
      position="relative"
      sx={{
        ":before": {
          position: "absolute",
          content: '""',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          transform: "skewY(-8deg)",
          backgroundColor,
        },
      }}
    >
      <Stack
        direction="column"
        spacing={16}
        align="center"
        margin="0 auto"
        position="relative"
        maxW="70rem"
      >
        <Heading
          as="h2"
          id="info-heading"
          fontSize="4xl"
          lineHeight="2.75rem"
          maxW="47.5rem"
          textAlign="center"
        >
          {heading}
        </Heading>
        <SimpleGrid columns={[1, 1, 2, 3, 3]} gap={6}>
          {(cards ?? cardsOneColumn).map((cardArr, idx) => (
            <Stack key={`stack-${idx}`} spacing={6}>
              {cardArr.map((card) => (
                <Card key={card.title}>
                  <CardHeading>
                    <CardIcon icon={card.icon} />
                    <CardTitle>{card.title}</CardTitle>
                  </CardHeading>
                  <CardDescription>{card.description}</CardDescription>
                </Card>
              ))}
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
    </Box>
  )
})

export default Info
