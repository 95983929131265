import React from "react"
import Header from "src/components/layout/Header"
import Main from "src/components/layout/Main"
import Info from "src/components/layout/Info"
import Footer from "src/components/layout/Footer"
import Tour from "src/components/layout/Tour"
import useDefaultSettings from "src/hooks/global/useDefaultSettings"
import useMonacoThemes from "src/hooks/global/useMonacoThemes"

export const App: React.FC = React.memo(() => {
  useDefaultSettings()
  useMonacoThemes()

  return (
    <>
      <Header />
      <Main />
      <Info />
      <Footer />
      <Tour />
    </>
  )
})

export default App
