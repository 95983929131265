import { Box, useColorModeValue, useTheme } from "@chakra-ui/react"
import React from "react"
import useTabContent from "src/hooks/data/useTabContent"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useOnEditorChange from "src/hooks/handlers/useOnEditorChange"
import BodyLoader from "src/components/workspace/common/BodyLoader"
import { initVimMode } from "monaco-vim"
import * as monaco from "monaco-editor/esm/vs/editor/editor.api"
import useResizeObserver from "use-resize-observer"
import { useSettingsContext } from "src/context/SettingsProvider"

const Editor: React.FC = () => {
  const theme = useTheme()
  const monacoTheme = useColorModeValue("lightCustom", "darkCustom")
  const backgroundColor = useColorModeValue("#f7fafc", "#1f2430")

  const { workspace } = useWorkspaceContext()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const { editorSpacing, editorFontSize, isVimEnabled } = useSettingsContext()

  const editorDivRef = React.useRef<HTMLDivElement>(null)
  const statusBarDivRef = React.useRef<HTMLDivElement>(null)
  const editorRef = React.useRef<monaco.editor.IStandaloneCodeEditor>()
  const tabContentRef = React.useRef<string>()

  const onEditorChange = useOnEditorChange(workspace.activeTabId)
  const { ref: innerContainerRef } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height, width }) => {
      if (
        editorRef.current &&
        typeof height !== "undefined" &&
        typeof width !== "undefined" &&
        typeof isVimEnabled !== "undefined"
      ) {
        editorRef.current.layout({ height: height - 26 * +isVimEnabled, width })
      }
    },
  })

  const readyToDisplayEditor = typeof tabContent?.data !== "undefined"

  React.useEffect(() => {
    let vimMode: any
    if (readyToDisplayEditor && editorDivRef.current) {
      editorRef.current = monaco.editor.create(editorDivRef.current, {
        language: "json",
        minimap: { enabled: false },
        fontFamily: theme.fonts.mono,
        fontLigatures: true,
        fontSize: editorFontSize,
        theme: monacoTheme,
      })

      if (isVimEnabled) {
        vimMode = initVimMode(editorRef.current, statusBarDivRef.current)
      }

      editorRef.current.onDidChangeModelContent(() => {
        if (editorRef.current) onEditorChange(editorRef.current.getValue())
      })

      editorRef.current.getModel()?.updateOptions({ tabSize: editorSpacing })

      if (typeof tabContentRef?.current !== "undefined") {
        editorRef.current.setValue(tabContentRef.current)
      }
    }

    return () => {
      vimMode?.dispose()
      editorRef.current?.dispose()
    }
  }, [
    readyToDisplayEditor,
    monacoTheme,
    theme.fonts.mono,
    onEditorChange,
    editorFontSize,
    editorSpacing,
    isVimEnabled,
  ])

  React.useEffect(() => {
    if (editorRef.current) {
      monaco.editor.setTheme(monacoTheme)
    }
  }, [monacoTheme])

  React.useEffect(() => {
    // We receive updates via the editor and autoformat actions:
    // 1. For editor actions, we do not set value in editor to avoid a bad
    //    typing experience.
    // 2. For autoformat actions, we set the value in the editor to explicitly
    //    change the text.
    // Regardless of action type, we store a ref to tabContent.data which is
    // ultimately the source of truth.
    if (typeof tabContent?.data === "undefined") return

    if (typeof editorRef?.current === "undefined") {
      const interval = setInterval(() => {
        if (typeof editorRef?.current !== "undefined") {
          editorRef.current.setValue(tabContent.data)
          clearInterval(interval)
        }
      }, 50)
    } else {
      tabContentRef.current = tabContent.data

      if (tabContent.data !== editorRef.current.getValue()) {
        editorRef.current.setValue(tabContent.data)
      }
    }
  }, [tabContent])

  return (
    <Box
      id={`editor-${workspace.id}`}
      width="100%"
      flex={1}
      position="relative"
    >
      <Box
        height="100%"
        width="100%"
        ref={innerContainerRef}
        overflow="hidden"
        position="absolute"
        borderRadius="0.25rem"
      >
        {readyToDisplayEditor ? (
          <>
            <Box
              height={`calc(100% - ${26 * +!!isVimEnabled}px)`}
              width="100%"
              ref={editorDivRef}
            />
            <Box
              ref={statusBarDivRef}
              fontSize="xs"
              px={2}
              py={1}
              bgColor={backgroundColor}
            />
          </>
        ) : (
          <BodyLoader />
        )}
      </Box>
    </Box>
  )
}

export default Editor
