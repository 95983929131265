import React from "react"
import useWorkspaceQuery from "src/hooks/queries/useWorkspaceQuery"
import { Workspace } from "src/types"

const defaultValue = {
  areWorkspacesLoading: true,
  workspaces: [],
}

export type WorkspacesContextValue = {
  areWorkspacesLoading: boolean
  workspaces: Workspace[]
}

const WorkspacesContext = React.createContext<WorkspacesContextValue>(
  defaultValue
)

export const WorkspacesProvider: React.FC = ({ children }) => {
  const { data: workspace0 } = useWorkspaceQuery(0)
  const { data: workspace1 } = useWorkspaceQuery(1)
  const { data: workspace2 } = useWorkspaceQuery(2)
  const { data: workspace3 } = useWorkspaceQuery(3)

  const value = React.useMemo<WorkspacesContextValue>(
    () =>
      workspace0 && workspace1 && workspace2 && workspace3
        ? {
            areWorkspacesLoading: false,
            workspaces: [workspace0, workspace1, workspace2, workspace3],
          }
        : defaultValue,
    [workspace0, workspace1, workspace2, workspace3]
  )

  return (
    <WorkspacesContext.Provider value={value}>
      {children}
    </WorkspacesContext.Provider>
  )
}

export const useWorkspacesContext = () => {
  const context = React.useContext(WorkspacesContext)
  if (context === undefined) {
    throw new Error(
      "useWorkspacesContext must be used within a WorkspacesProvider"
    )
  }
  return context
}
