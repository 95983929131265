import { useQuery } from "react-query"
import { useDb } from "src/context/DbProvider"

const useTabContentQuery = (id: string) => {
  const db = useDb()

  return useQuery(`tab_content:${id}`, async () => {
    const tabContent = await db.tabContent.get(id)
    if (tabContent) return tabContent

    const newTabContent = { id, data: "" }
    await db.tabContent.put(newTabContent)
    return newTabContent
  })
}

export default useTabContentQuery
