import React from "react"
import { FormControl, FormLabel, Switch } from "@chakra-ui/react"
import usePrimaryColorScheme from "src/hooks/theme/usePrimaryColorScheme"
import { useSettingsContext } from "src/context/SettingsProvider"

const ELEMENT_ID = "settings-vim-mode"

const EditorVimMode: React.FC = () => {
  const primaryColorScheme = usePrimaryColorScheme()
  const { isVimEnabled, setIsVimEnabled } = useSettingsContext()

  return typeof isVimEnabled !== "undefined" ? (
    <FormControl>
      <FormLabel htmlFor={ELEMENT_ID}>Vim mode</FormLabel>
      <Switch
        id={ELEMENT_ID}
        isChecked={isVimEnabled}
        onChange={(event) => setIsVimEnabled(event.target.checked)}
        colorScheme={primaryColorScheme}
      />
    </FormControl>
  ) : null
}

export default EditorVimMode
