import React from "react"
import useAllTabs from "src/hooks/data/useAllTabs"
import { WorkspaceTab } from "src/types"

export type WorkspacesContextValue = {
  allTabs?: WorkspaceTab[]
  newTabDefaultName: string
}

const TabsContext = React.createContext<WorkspacesContextValue | undefined>(
  undefined
)

const findNewTabDefaultName = (allTabs?: WorkspaceTab[]) => {
  if (!allTabs) return "1"

  const tabNames = new Set(allTabs.map((t) => t.name))

  return Array.from(tabNames).reduce<string>((acc) => {
    if (!tabNames.has(acc)) return acc
    return (+acc + 1).toString()
  }, "1")
}

export const TabsProvider: React.FC = ({ children }) => {
  const allTabs = useAllTabs()

  const value = React.useMemo(
    () => ({
      allTabs,
      newTabDefaultName: findNewTabDefaultName(allTabs),
    }),
    [allTabs]
  )

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
}

export const useTabsContext = () => {
  const context = React.useContext(TabsContext)
  if (context === undefined) {
    throw new Error("useTabsContext must be used within a TabsProvider")
  }
  return context
}
