import React from "react"
import useBoolSetting from "src/hooks/settings/useBoolSetting"
import useNumberSetting from "src/hooks/settings/useNumberSetting"

const emptySettings = {
  isSettingsLoading: true,
  editorSpacing: undefined,
  setEditorSpacing: async () => {},
  editorFontSize: undefined,
  setEditorFontSize: async () => {},
  isVimEnabled: undefined,
  setIsVimEnabled: async () => {},
  isNewTabFormEnabled: undefined,
  setIsNewTabFormEnabled: async () => {},
  isHorizontalSplitScreenEnabled: undefined,
  setIsHorizontalSplitScreenEnabled: async () => {},
  isVerticalSplitScreenEnabled: undefined,
  setIsVerticalSplitScreenEnabled: async () => {},
}

const SettingsContext = React.createContext<
  | {
      isSettingsLoading: boolean
      editorSpacing: number | undefined
      setEditorSpacing(value: number): Promise<void>
      editorFontSize: number | undefined
      setEditorFontSize(value: number): Promise<void>
      isVimEnabled: boolean | undefined
      setIsVimEnabled(value: boolean): Promise<void>
      isNewTabFormEnabled: boolean | undefined
      setIsNewTabFormEnabled(value: boolean): Promise<void>
      isHorizontalSplitScreenEnabled: boolean | undefined
      setIsHorizontalSplitScreenEnabled(value: boolean): Promise<void>
      isVerticalSplitScreenEnabled: boolean | undefined
      setIsVerticalSplitScreenEnabled(value: boolean): Promise<void>
    }
  | {
      isSettingsLoading: boolean
      editorSpacing: undefined
      setEditorSpacing(): Promise<void>
      editorFontSize: undefined
      setEditorFontSize(): Promise<void>
      isVimEnabled: undefined
      setIsVimEnabled(): Promise<void>
      isNewTabFormEnabled: undefined
      setIsNewTabFormEnabled(): Promise<void>
      isHorizontalSplitScreenEnabled: undefined
      setIsHorizontalSplitScreenEnabled(): Promise<void>
      isVerticalSplitScreenEnabled: undefined
      setIsVerticalSplitScreenEnabled(): Promise<void>
    }
>(emptySettings)

export const SettingsProvider: React.FC = ({ children }) => {
  const [editorSpacing, setEditorSpacing] = useNumberSetting("editor_spacing")
  const [isVimEnabled, setIsVimEnabled] = useBoolSetting("editor_vim_enabled")
  const [editorFontSize, setEditorFontSize] = useNumberSetting(
    "editor_font_size"
  )
  const [isNewTabFormEnabled, setIsNewTabFormEnabled] = useBoolSetting(
    "new_tab_form_enabled"
  )
  const [
    isHorizontalSplitScreenEnabled,
    setIsHorizontalSplitScreenEnabled,
  ] = useBoolSetting("h_split_screen_enabled")
  const [
    isVerticalSplitScreenEnabled,
    setIsVerticalSplitScreenEnabled,
  ] = useBoolSetting("v_split_screen_enabled")

  const value = React.useMemo(() => {
    const isSettingsLoading = [
      editorSpacing,
      isVimEnabled,
      editorFontSize,
      isNewTabFormEnabled,
      isHorizontalSplitScreenEnabled,
      isVerticalSplitScreenEnabled,
    ].some((v) => typeof v === "undefined")

    return !isSettingsLoading
      ? {
          isSettingsLoading,
          editorSpacing,
          setEditorSpacing,
          isVimEnabled,
          setIsVimEnabled,
          editorFontSize,
          setEditorFontSize,
          isNewTabFormEnabled,
          setIsNewTabFormEnabled,
          isHorizontalSplitScreenEnabled,
          setIsHorizontalSplitScreenEnabled,
          isVerticalSplitScreenEnabled,
          setIsVerticalSplitScreenEnabled,
        }
      : emptySettings
  }, [
    editorSpacing,
    setEditorSpacing,
    isVimEnabled,
    setIsVimEnabled,
    editorFontSize,
    setEditorFontSize,
    isNewTabFormEnabled,
    setIsNewTabFormEnabled,
    isHorizontalSplitScreenEnabled,
    setIsHorizontalSplitScreenEnabled,
    isVerticalSplitScreenEnabled,
    setIsVerticalSplitScreenEnabled,
  ])

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettingsContext = () => {
  const context = React.useContext(SettingsContext)
  if (context === undefined) {
    throw new Error("useSettingsContext must be used within a SettingsProvider")
  }
  return context
}
