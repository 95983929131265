import React from "react"
import Database from "src/database/Database"

const DbContext = React.createContext<Database | undefined>(undefined)

export const DbProvider: React.FC = ({ children }) => {
  const db = React.useMemo(() => new Database(), [])

  return <DbContext.Provider value={db}>{children}</DbContext.Provider>
}

export const useDb = () => {
  const context = React.useContext(DbContext)
  if (context === undefined) {
    throw new Error("useDb must be used within a DbProvider")
  }
  return context
}
