import React from "react"
import { Heading } from "@chakra-ui/react"

const CardTitle: React.FC = ({ children }) => {
  return (
    <Heading as="h3" fontSize="xl">
      {children}
    </Heading>
  )
}

export default CardTitle
