import {
  Flex,
  Box,
  useColorMode,
  ColorMode,
  CloseButton,
} from "@chakra-ui/react"
import React from "react"
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd"
import { WorkspaceTab } from "src/types"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useResizeObserver from "use-resize-observer"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { EVENTS } from "src/constants/analytics"

type TabProps = {
  dragProvided: DraggableProvided
  dragSnapshot: DraggableStateSnapshot
  tab: WorkspaceTab
  parentHasRenderedOnceRef: React.MutableRefObject<boolean>
}

/* istanbul ignore next */
const generateTextStyles = (colorMode: ColorMode) => {
  return {
    backgroundClip: "text",
    color: "transparent",
    backgroundImage:
      colorMode === "light"
        ? "linear-gradient(90deg,rgba(26,32,44,1),rgba(26,32,44,0.9),rgba(26,32,44,0.8),rgba(26,32,44,0.6),transparent)"
        : "linear-gradient(90deg,rgba(255,255,255,1),rgba(255,255,255,0.9),rgba(255,255,255,0.8),rgba(255,255,255,0.6),transparent)",
  }
}

const Tab: React.FC<TabProps> = ({
  dragProvided,
  dragSnapshot,
  tab,
  parentHasRenderedOnceRef,
}) => {
  const { colorMode } = useColorMode()
  const { workspace, setActiveTab, closeTab } = useWorkspaceContext()
  const isActiveTab = workspace.activeTabId === tab.id
  const isActiveOrDraggingTab = isActiveTab || dragSnapshot.isDragging
  const tabRef = React.useRef<HTMLDivElement | null>(null)
  const [textStyles, setTextStyles] = React.useState({})
  const { ref: textRef } = useResizeObserver<HTMLDivElement>({
    onResize: () => {
      /* istanbul ignore next */
      if (textRef?.current) {
        setTextStyles(
          textRef.current.clientWidth === textRef.current.scrollWidth
            ? {}
            : generateTextStyles(colorMode)
        )
      }
    },
  })
  const { analytics } = useFirebaseContext()

  const onTabClick = React.useCallback(() => {
    if (workspace.activeTabId !== tab.id) {
      setActiveTab(tab.id).then(() =>
        analytics.logEvent(EVENTS.TAB_ACTIVATE_CLICK)
      )
    }
  }, [workspace.activeTabId, setActiveTab, tab, analytics])

  const onCloseClick = React.useCallback(
    (event: React.MouseEvent<any, MouseEvent>) => {
      event.stopPropagation()
      closeTab(tab.id).then(() => analytics.logEvent(EVENTS.TAB_CLOSE))
    },
    [closeTab, tab.id, analytics]
  )

  React.useEffect(() => {
    if (tabRef?.current && isActiveTab && !parentHasRenderedOnceRef.current) {
      tabRef.current.scrollIntoView({ inline: "center", block: "end" })
    }
  }, [isActiveTab, parentHasRenderedOnceRef])

  React.useEffect(() => {
    /* istanbul ignore next */
    if (
      textRef.current &&
      textRef.current.clientWidth !== textRef.current.scrollWidth
    ) {
      setTextStyles(generateTextStyles(colorMode))
    }
  }, [colorMode, setTextStyles, textRef])

  return (
    <Flex
      ref={(ref) => {
        tabRef.current = ref
        return dragProvided.innerRef(ref)
      }}
      {...dragProvided.dragHandleProps}
      {...dragProvided.draggableProps}
      maxWidth={150}
      minWidth={75}
      height={8}
      align="center"
      padding="0 0.25rem 0 0.5rem"
      position="relative"
      transition="background-color 250ms"
      borderRadius="0.25rem 0.25rem 0 0"
      tabIndex={0}
      sx={{
        ":hover": {
          ...(!isActiveOrDraggingTab
            ? {
                backgroundColor: colorMode === "light" ? "gray.50" : "gray.700",
              }
            : {}),
        },
        ":active": {
          backgroundColor: colorMode === "light" ? "white" : "gray.600",
        },
        ":not(:first-of-type)": {
          marginLeft: "-1px",
          "::before": {
            content: '""',
            borderStyle: "solid",
            borderColor: isActiveOrDraggingTab
              ? colorMode === "light"
                ? "white"
                : "gray.600"
              : colorMode === "light"
              ? "gray.400"
              : "gray.600",
            borderWidth: "0 1px 0 0",
            height: "1.25rem",
            width: "0px",
            position: "absolute",
            left: 0,
            transition: "border-color 250ms",
            ...(isActiveOrDraggingTab ? { zIndex: 1 } : {}),
          },
        },
        "::after": {
          content: '""',
          borderStyle: "solid",
          borderColor: isActiveOrDraggingTab
            ? colorMode === "light"
              ? "white"
              : "gray.600"
            : colorMode === "light"
            ? "gray.400"
            : "gray.600",
          borderWidth: "0 0 0 1px",
          height: "1.25rem",
          width: "0px",
          position: "absolute",
          right: 0,
          transition: "border-color 250ms",
          ...(isActiveOrDraggingTab ? { zIndex: 1 } : {}),
        },
        ":hover::before, :hover::after": {
          borderColor:
            colorMode === "light"
              ? isActiveOrDraggingTab
                ? "white"
                : "gray.50"
              : isActiveOrDraggingTab
              ? "gray.600"
              : "gray.700",
          zIndex: 1,
        },
        ":active::before,  :active::after": {
          borderColor: colorMode === "light" ? "white" : "gray.600",
          zIndex: 1,
        },
      }}
      {...(isActiveOrDraggingTab
        ? {
            backgroundColor: colorMode === "light" ? "white" : "gray.600",
          }
        : {})}
      cursor="pointer !important"
      role="tab"
      onClick={onTabClick}
    >
      <Box overflowX="hidden" whiteSpace="nowrap" fontSize={14} mr="auto">
        <Box ref={textRef} sx={textStyles}>
          {tab.name}
        </Box>
      </Box>
      <CloseButton
        aria-label="Close tab"
        size="sm"
        variant="ghost"
        ml={2}
        onClick={onCloseClick}
        {...(colorMode === "light"
          ? {
              _hover: { backgroundColor: "gray.200" },
              _active: { backgroundColor: "gray.300" },
            }
          : {})}
      />
    </Flex>
  )
}

export default Tab
