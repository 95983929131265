import { useQuery } from "react-query"
import { useDb } from "src/context/DbProvider"

const useFlagQuery = (id: string) => {
  const db = useDb()

  return useQuery(`flag:${id}`, async () => {
    const flag = await db.flag.get(id)
    return flag
  })
}

export default useFlagQuery
