export const recursivePropertyNameSort = (
  obj: any,
  order: "asc" | "desc"
): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) =>
      typeof item === "object" && item !== null
        ? recursivePropertyNameSort(item, order)
        : item
    )
  }

  const keys = Object.keys(obj).sort((a, b) =>
    order === "asc"
      ? a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
      : b.toLocaleLowerCase().localeCompare(a.toLocaleLowerCase())
  )

  return keys.reduce((acc: any, key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      acc[key] = recursivePropertyNameSort(obj[key], order)
    } else {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}
