import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import WorkspaceComponent from "src/components/workspace/Workspace"
import { useWorkspacesContext } from "src/context/WorkspacesProvider"

const SinglePane: React.FC = () => {
  const { workspaces } = useWorkspacesContext()

  return (
    <Flex
      direction="row"
      height="100%"
      width="100%"
      maxW="96rem"
      align="center"
      justify="center"
      px={6}
      data-testid="single-pane"
    >
      <Box height="100%" width="100%">
        <WorkspaceComponent workspace={workspaces[0]} />
      </Box>
    </Flex>
  )
}

export default SinglePane
