import React from "react"
import { TabMode, Workspace, WorkspaceTab } from "src/types"
import useCloseTabMutation from "src/hooks/mutations/useCloseTabMutation"
import useCreateTabMutation from "src/hooks/mutations/useCreateTabMutation"
import useSetActiveTabMutation from "src/hooks/mutations/useSetActiveTabMutation"
import useSetTabModeMutation from "src/hooks/mutations/useSetTabModeMutation"
import useSetTabNameMutation from "src/hooks/mutations/useSetTabNameMutation"
import { getActiveTab } from "src/utils/workspaceUtils"

type WorkspaceProviderProps = {
  workspace: Workspace
}

const WorkspaceContext = React.createContext<
  | {
      workspace: Workspace
      activeTab?: WorkspaceTab
      closeTab(tabId: string): Promise<void>
      createTab(params: { tabName: string }): Promise<void>
      setActiveTab(tabId: string): Promise<void>
      setTabMode(params: { tabMode: TabMode; tabId: string }): Promise<void>
      setTabName(params: { tabName: string; tabId: string }): Promise<void>
    }
  | undefined
>(undefined)

export const WorkspaceProvider: React.FC<WorkspaceProviderProps> = ({
  workspace,
  children,
}) => {
  const createTabMutation = useCreateTabMutation(workspace)
  const closeTabMutation = useCloseTabMutation(workspace)
  const setActiveTabMutation = useSetActiveTabMutation(workspace)
  const setTabNameMutation = useSetTabNameMutation(workspace)
  const setTabModeMutation = useSetTabModeMutation(workspace)

  const value = React.useMemo(
    () => ({
      workspace,
      activeTab: getActiveTab(workspace),
      createTab: async (params: { tabName: string }) => {
        await createTabMutation.mutateAsync(params)
      },
      closeTab: async (tabId: string) => {
        await closeTabMutation.mutateAsync(tabId)
      },
      setTabMode: async (params: { tabMode: TabMode; tabId: string }) => {
        await setTabModeMutation.mutateAsync(params)
      },
      setActiveTab: async (tabId: string) => {
        await setActiveTabMutation.mutateAsync(tabId)
      },
      setTabName: async (params: { tabName: string; tabId: string }) => {
        await setTabNameMutation.mutateAsync(params)
      },
    }),
    [
      workspace,
      createTabMutation,
      closeTabMutation,
      setTabModeMutation,
      setActiveTabMutation,
      setTabNameMutation,
    ]
  )

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}

export const useWorkspaceContext = () => {
  const context = React.useContext(WorkspaceContext)
  if (context === undefined) {
    throw new Error(
      "useWorkspaceContext must be used within a WorkspaceProvider"
    )
  }
  return context
}
