import React from "react"
import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react"
import { useSettingsContext } from "src/context/SettingsProvider"

const ELEMENT_ID = "settings-editor_font_size"

const EditorFontSize: React.FC = () => {
  const { editorFontSize, setEditorFontSize } = useSettingsContext()

  return typeof editorFontSize !== "undefined" ? (
    <FormControl>
      <FormLabel htmlFor={ELEMENT_ID}>Font size</FormLabel>
      <NumberInput
        id={ELEMENT_ID}
        onChange={(event) => {
          const fontSize = +event
          if (!Number.isNaN(fontSize) && 4 <= fontSize && fontSize <= 32) {
            setEditorFontSize(fontSize)
          }
        }}
        min={4}
        max={32}
        defaultValue={editorFontSize}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  ) : null
}

export default EditorFontSize
