import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react"
import React from "react"
import { useCombobox } from "downshift"
import { WorkspaceTab } from "src/types"
import { Search } from "js-search"
import { useWorkspacesContext } from "src/context/WorkspacesProvider"
import useSetActiveTabMutation from "src/hooks/mutations/useSetActiveTabMutation"
import { SearchIcon } from "@chakra-ui/icons"
import { useSettingsContext } from "src/context/SettingsProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { useTabsContext } from "src/context/TabsProvider"
import { EVENTS } from "src/constants/analytics"

type SearchRef = {
  isIndexing: boolean
  search?: Search
}

const TabSearch: React.FC = () => {
  const boxShadow = useColorModeValue("base", "dark-lg")
  const listBackgroundColor = useColorModeValue("white", "gray.700")
  const listItemHighlightColor = useColorModeValue("gray.100", "whiteAlpha.200")
  const noneFoundColor = useColorModeValue("gray.500", "gray.400")

  const { workspaces } = useWorkspacesContext()
  const setActiveTabMutations = [
    useSetActiveTabMutation(workspaces[0]),
    useSetActiveTabMutation(workspaces[1]),
    useSetActiveTabMutation(workspaces[2]),
    useSetActiveTabMutation(workspaces[3]),
  ]
  const { allTabs } = useTabsContext()
  const {
    isHorizontalSplitScreenEnabled,
    isVerticalSplitScreenEnabled,
    setIsHorizontalSplitScreenEnabled,
    setIsVerticalSplitScreenEnabled,
  } = useSettingsContext()
  const searchRef = React.useRef<SearchRef>({ isIndexing: false })
  const { analytics } = useFirebaseContext()

  const [tabList, setTabList] = React.useState<WorkspaceTab[]>()

  React.useEffect(() => {
    if (!allTabs) return

    searchRef.current.isIndexing = true

    searchRef.current.search = new Search("id")
    searchRef.current.search.addIndex("name")

    const documents: any = allTabs
    searchRef.current.search.addDocuments(documents)

    searchRef.current.isIndexing = false
  }, [allTabs])

  React.useEffect(() => {
    setTabList(allTabs)
  }, [setTabList, allTabs])

  const {
    isOpen,
    reset,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: tabList ?? [],
    defaultIsOpen: false,
    onInputValueChange: ({ inputValue }) => {
      if (
        allTabs &&
        typeof inputValue === "string" &&
        searchRef?.current?.search &&
        !searchRef.current.isIndexing
      ) {
        if (!inputValue.length) {
          setTabList(allTabs)
        } else {
          const results: any = searchRef.current.search.search(inputValue)
          setTabList(results)
        }
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!workspaces || !selectedItem?.id) return

      const workspace = workspaces.find((w) =>
        w.tabs.some((tab) => tab.id === selectedItem.id)
      )
      // workspace should always be defined since tabs are derived from
      // workspaces
      /* istanbul ignore next */
      if (workspace) {
        analytics.logEvent(EVENTS.HEADER_SEARCH_SELECT, {
          workspace_id: workspace.id,
        })
        if (workspace.id > 0 && !isHorizontalSplitScreenEnabled) {
          setIsHorizontalSplitScreenEnabled(true)
        }
        if (workspace.id > 1 && !isVerticalSplitScreenEnabled) {
          setIsVerticalSplitScreenEnabled(true)
        }
        const setActiveTabMutation = setActiveTabMutations[workspace.id]
        setActiveTabMutation.mutate(selectedItem.id)
      }
      reset()
    },
    itemToString: (item) =>
      /* istanbul ignore next */
      item?.name ?? "",
  })

  return (
    <Box position="relative">
      <Box {...getComboboxProps()}>
        <InputGroup size="sm">
          <InputLeftElement
            size="sm"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            {...getInputProps({ onFocus: openMenu })}
            placeholder="Search tab names"
            aria-label="Search tab names"
            size="sm"
          />
        </InputGroup>
      </Box>
      <List
        display={isOpen ? undefined : "none"}
        paddingY={2}
        overflowY="auto"
        maxH={40}
        zIndex={1}
        position="absolute"
        top="2.5rem"
        width="100%"
        borderRadius="0.25rem"
        backgroundColor={listBackgroundColor}
        boxShadow={boxShadow}
        {...getMenuProps()}
      >
        {tabList?.length ? (
          tabList.map((tab, idx) => (
            <ListItem
              key={`${tab.name}${idx}`}
              {...getItemProps({ item: tab, index: idx })}
              backgroundColor={
                idx === highlightedIndex ? listItemHighlightColor : undefined
              }
            >
              <Box paddingY={1} paddingX={4}>
                {tab.name}
              </Box>
            </ListItem>
          ))
        ) : (
          <Box paddingY={1} paddingX={4} color={noneFoundColor}>
            No tabs found
          </Box>
        )}
      </List>
    </Box>
  )
}

export default TabSearch
