import React from "react"
import { useDisclosure } from "@chakra-ui/react"

type Placement = "top" | "left" | "bottom" | "right"

type RenderButtonProps = {
  onClick: () => void
}

type RenderDrawerProps = {
  placement: Placement
  onClose: () => void
  isOpen: boolean
}

type DrawerTriggerProps = {
  placement: Placement
  renderButton: React.FC<RenderButtonProps>
  renderDrawer: React.FC<RenderDrawerProps>
}

const DrawerTrigger: React.FC<DrawerTriggerProps> = ({
  renderButton,
  renderDrawer,
  placement,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {renderButton({ onClick: onOpen })}
      {renderDrawer({ placement, isOpen, onClose })}
    </>
  )
}

export default DrawerTrigger
