import monaco from "monaco-editor"

export const lightTheme: monaco.editor.IStandaloneThemeData = {
  base: "vs",
  inherit: true,
  rules: [
    {
      foreground: "#3c6dd6",
      token: "keyword.json",
    },
    {
      foreground: "#c24c44",
      token: "string.value.json",
    },
    {
      foreground: "#3c6dd6",
      token: "number.json",
    },
    {
      foreground: "#026c6d",
      token: "string.key.json",
    },
    {
      foreground: "#abb0b6",
      token: "comment.block.json",
    },
    {
      foreground: "#abb0b6",
      token: "comment.line.json",
    },
  ],
  colors: {
    "editor.foreground": "#575f66",
    "editor.background": "#f7fafc",
    "editor.selectionBackground": "#d1e4f4",
    "editor.lineHighlightBackground": "#8a91991a",
    "editorCursor.foreground": "#73b6c1",
    "editorLineNumber.foreground": "#318f9e",
  },
}

export const darkTheme: monaco.editor.IStandaloneThemeData = {
  base: "vs-dark",
  inherit: true,
  rules: [
    {
      foreground: "#ffcc66",
      token: "keyword.json",
    },
    {
      foreground: "#cfff8c",
      token: "string.value.json",
    },
    {
      foreground: "#ffcc66",
      token: "number.json",
    },
    {
      foreground: "#66e5ff",
      token: "string.key.json",
    },
    {
      foreground: "#5c6773",
      token: "comment.block.json",
    },
    {
      foreground: "#5c6773",
      token: "comment.line.json",
    },
  ],
  colors: {
    "editor.foreground": "#cbccc6",
    "editor.background": "#1f2430",
    "editor.selectionBackground": "#33415e",
    "editor.lineHighlightBackground": "#191e2a",
    "editorCursor.foreground": "#ffcc66",
    "editorWidget.background": "#232834",
    "widget.shadow": "#141925",
    "editorWidget.border": "#101521",
    "input.background": "#1c222f",
    "input.border": "#707a8c",
    "inputOption.activeBorder": "#707a8c",
  },
}
