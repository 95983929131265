import { Button } from "@chakra-ui/react"
import React from "react"
import { EVENTS } from "src/constants/analytics"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { Workbox, messageSW } from "workbox-window"

const UpdateVersionButton: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(false)
  const workboxRef = React.useRef<Workbox>()
  const registrationRef = React.useRef<ServiceWorkerRegistration>()
  const { analytics } = useFirebaseContext()

  const onClick = () => {
    if (!registrationRef?.current?.waiting || !workboxRef?.current) return

    analytics.logEvent(EVENTS.UPDATE_VERSION)
    workboxRef.current.addEventListener("controlling", () =>
      window.location.reload()
    )
    messageSW(registrationRef.current.waiting, { type: "SKIP_WAITING" })
  }

  React.useEffect(() => {
    if (
      process.env.NODE_ENV !== "production" ||
      !("serviceWorker" in navigator)
    ) {
      return
    }

    workboxRef.current = new Workbox(
      `${process.env.PUBLIC_URL}/service-worker.js`
    )

    const showSkipWaitingPrompt = () => setIsVisible(true)

    workboxRef.current.addEventListener("waiting", showSkipWaitingPrompt)
    workboxRef.current.addEventListener(
      "externalwaiting",
      showSkipWaitingPrompt
    )
    workboxRef.current.addEventListener("externalactivated", () =>
      window.location.reload()
    )

    workboxRef.current.register().then((r) => (registrationRef.current = r))
  }, [setIsVisible])

  return isVisible ? (
    <Button size="xs" mt="0.25rem !important" onClick={onClick}>
      Update available
    </Button>
  ) : null
}

export default UpdateVersionButton
