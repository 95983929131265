import React from "react"
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Flex,
  Heading,
} from "@chakra-ui/react"
import EditorSpacing from "src/components/drawers/settings/EditorSpacing"
import EditorVimMode from "src/components/drawers/settings/EditorVimMode"
import EditorFontSize from "src/components/drawers/settings/EditorFontSize"
import NewTabBehavior from "src/components/drawers/settings/NewTabBehavior"

type SettingsDrawerProps = {
  placement: "top" | "left" | "bottom" | "right"
  onClose: () => void
  isOpen: boolean
}

const SettingsDrawer: React.FC<SettingsDrawerProps> = ({
  placement,
  onClose,
  isOpen,
}) => {
  return (
    <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Settings</DrawerHeader>
          <DrawerBody>
            <Flex
              direction="column"
              sx={{
                "> div": {
                  marginBottom: 4,
                },
              }}
            >
              <Heading as="h3" fontSize="1.5rem" mb={1}>
                App
              </Heading>
              <NewTabBehavior />
              <Heading as="h3" fontSize="1.5rem" mb={1}>
                Text Editor
              </Heading>
              <EditorVimMode />
              <EditorSpacing />
              <EditorFontSize />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  )
}

export default SettingsDrawer
