import React from "react"
import Joyride from "react-joyride"
import usePrimaryColor from "src/hooks/theme/usePrimaryColor"
import CustomTooltip from "src/components/layout/joyride/CustomTooltip"
import { useColorModeValue } from "@chakra-ui/react"
import useFlagQuery from "src/hooks/queries/useFlagQuery"
import useSetFlagMutation from "src/hooks/mutations/useSetFlagMutation"

export const steps = [
  {
    target: "#header-right-section",
    title: "Configure",
    content:
      "Search for tabs, toggle between split screens, and configure settings to customize your experience.",
  },
  {
    target: "#tab-list-0",
    title: "Tabs",
    content:
      "Work with multiple JSON documents at a time by leveraging tabs for an IDE-like experience within the browser.",
  },
  {
    target: "#controls-0",
    title: "Controls",
    content:
      "This is your toolbar of utilities to help you manage and format your JSON.",
  },
  {
    target: "#info-heading",
    title: "Info",
    content:
      "Here you'll find more information on JSON Tabs features and service-level agreements.",
  },
]

const Tour: React.FC = () => {
  const primaryColor = usePrimaryColor()
  const arrowColor = useColorModeValue("#fff", "#2d3748")
  const { data: isTourCompletedFlag, isLoading } = useFlagQuery(
    "is_tour_completed"
  )
  const [run, setRun] = React.useState(false)
  const isTourCompletedMutation = useSetFlagMutation("is_tour_completed")

  React.useEffect(() => {
    if (isLoading) return

    if (typeof isTourCompletedFlag === "undefined") setRun(true)
    else if (isTourCompletedFlag.isOn) setRun(false)
  }, [isLoading, isTourCompletedFlag])

  return (
    <Joyride
      steps={steps}
      styles={{ options: { primaryColor, arrowColor } }}
      tooltipComponent={CustomTooltip}
      continuous
      showProgress
      disableScrolling
      disableOverlayClose
      hideBackButton
      locale={{ last: "Close" }}
      run={run}
      callback={(props) => {
        if (
          props.action === "reset" ||
          (props.action === "close" && props.lifecycle === "complete")
        ) {
          isTourCompletedMutation.mutate(true)
        }
      }}
    />
  )
}

export default Tour
