import React from "react"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import useTabContent from "src/hooks/data/useTabContent"

type InitialTabContent = {
  id: string
  data?: string
  isCurrent: boolean
}

const useOriginalTabContent = () => {
  const { workspace } = useWorkspaceContext()
  const { tabContent } = useTabContent(workspace.activeTabId)
  const [initialTabContent, setInitialTabContent] = React.useState<
    InitialTabContent
  >()

  React.useEffect(() => {
    if (typeof tabContent?.data === "undefined") return

    if (workspace.activeTabId !== initialTabContent?.id) {
      setInitialTabContent({
        id: workspace.activeTabId,
        data: tabContent?.data,
        isCurrent: true,
      })
    } else if (tabContent.data === initialTabContent.data) {
      if (!initialTabContent.isCurrent) {
        setInitialTabContent({ ...initialTabContent, isCurrent: true })
      }
    } else if (initialTabContent.isCurrent) {
      setInitialTabContent({
        ...initialTabContent,
        isCurrent: false,
      })
    }
  }, [
    workspace.activeTabId,
    tabContent,
    initialTabContent,
    setInitialTabContent,
  ])

  return initialTabContent
}

export default useOriginalTabContent
