import React from "react"
import { useWorkspacesContext } from "src/context/WorkspacesProvider"

const useAllTabs = () => {
  const { workspaces, areWorkspacesLoading } = useWorkspacesContext()

  return React.useMemo(() => {
    if (areWorkspacesLoading) return undefined

    return workspaces
      .flatMap((w) => w.tabs)
      .sort((a, b) =>
        a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
      )
  }, [workspaces, areWorkspacesLoading])
}

export default useAllTabs
