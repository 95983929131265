import jsonParse from "json-parse-better-errors"
import simpleJsonRepair from "simple-json-repair"

const WHITESPACE_REGEX = new RegExp(/\s+/g)

export const cleanWhitespace = (str?: string) =>
  str?.replace(WHITESPACE_REGEX, " ").trim() ?? ""

const parseRecursive = (parseFn: (s: string) => any) => (str: string) => {
  let obj: any = str
  let attempts = 0

  while (typeof obj === "string" && attempts < 3) {
    obj = parseFn(obj)
    attempts += 1
  }

  return obj
}

const parseJsonWithRepair = parseRecursive((s) =>
  jsonParse(simpleJsonRepair(s))
)

const parseJsonWithBetterErrors = parseRecursive((s) => jsonParse(s))

export const parseJson = (str: string) => {
  let obj: any
  try {
    obj = parseJsonWithRepair(str)
  } catch (error) {
    obj = parseJsonWithBetterErrors(str)
  }
  return obj
}
