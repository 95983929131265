import React from "react"
import { Stack, useColorModeValue } from "@chakra-ui/react"

const Card: React.FC = ({ children }) => {
  const backgroundColor = useColorModeValue("white", "#242A38")

  return (
    <Stack
      p={8}
      borderRadius="0.75rem"
      spacing={4}
      backgroundColor={backgroundColor}
      boxShadow="base"
    >
      {children}
    </Stack>
  )
}

export default Card
