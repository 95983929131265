import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import React from "react"
import ReactDOM from "react-dom"
import App from "src/components/App"
import * as serviceWorkerRegistration from "src/serviceWorkerRegistration"
import chakraTheme from "src/theme/chakraTheme"
import { DbProvider } from "src/context/DbProvider"
import { QueryClient, QueryClientProvider } from "react-query"
import { FileUploadProvider } from "src/context/FileUploadProvider"
import { FirebaseProvider } from "src/context/FirebaseProvider"
import { SettingsProvider } from "src/context/SettingsProvider"
import { WorkspacesProvider } from "src/context/WorkspacesProvider"
import { TabsProvider } from "src/context/TabsProvider"

const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider>
      <ChakraProvider theme={chakraTheme}>
        <QueryClientProvider client={queryClient}>
          <DbProvider>
            <SettingsProvider>
              <WorkspacesProvider>
                <TabsProvider>
                  <FileUploadProvider>
                    <ColorModeScript initialColorMode="system" />
                    <App />
                  </FileUploadProvider>
                </TabsProvider>
              </WorkspacesProvider>
            </SettingsProvider>
          </DbProvider>
        </QueryClientProvider>
      </ChakraProvider>
    </FirebaseProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
