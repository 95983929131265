import React from "react"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  PopoverCloseButton,
  Portal,
} from "@chakra-ui/react"
import TabNameForm from "src/components/forms/TabNameForm"
import { useWorkspaceContext } from "src/context/WorkspaceProvider"
import { useFirebaseContext } from "src/context/FirebaseProvider"
import { getActiveTab } from "src/utils/workspaceUtils"
import { EVENTS } from "src/constants/analytics"

const TabName: React.FC = () => {
  const { workspace, setTabName } = useWorkspaceContext()
  const { analytics } = useFirebaseContext()
  const firstFieldRef = React.useRef<HTMLInputElement | null>(null)
  const tabName = React.useMemo(() => getActiveTab(workspace)?.name, [
    workspace,
  ])
  const [isOpen, setIsOpen] = React.useState(false)
  const { onClose, onOpen } = React.useMemo(
    () => ({ onClose: () => setIsOpen(false), onOpen: () => setIsOpen(true) }),
    [setIsOpen]
  )

  React.useEffect(() => {
    setIsOpen(false)
  }, [workspace.activeTabId])

  return (
    <Popover
      initialFocusRef={firstFieldRef}
      placement="bottom"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <Button
          size="xs"
          variant="ghost"
          onClick={() => {
            analytics.logEvent(EVENTS.WORKSPACE_CONTROL_CLICK, {
              name: "tab_name",
            })
            onOpen()
          }}
        >
          Tab name
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex={4} p={5}>
          <PopoverCloseButton />
          <TabNameForm
            firstFieldRef={firstFieldRef}
            onClose={onClose}
            submitMutation={setTabName}
            onSubmitSuccess={() => {
              analytics.logEvent(EVENTS.WORKSPACE_TAB_NAME_UPDATE)
            }}
            buttonText="Update Tab Name"
            tabId={workspace.activeTabId}
            defaultValues={{ tabName }}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export default TabName
