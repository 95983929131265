import { MutateFunction } from "react-query"
import { Setting } from "src/types"
import useSetSettingMutation from "src/hooks/mutations/useSetSettingMutation"
import useSettingQuery from "src/hooks/queries/useSettingQuery"

const useSetting = (
  id: string
): [Setting | undefined, MutateFunction<void, unknown, string, void>] => {
  const { data: setting } = useSettingQuery(id)
  const setSettingMutation = useSetSettingMutation(id)

  return [setting, setSettingMutation.mutateAsync]
}

export default useSetting
