import React from "react"
import { FormControl, FormLabel, Switch } from "@chakra-ui/react"
import usePrimaryColorScheme from "src/hooks/theme/usePrimaryColorScheme"
import { useSettingsContext } from "src/context/SettingsProvider"

const ELEMENT_ID = "settings-new-tab-behavior"

const NewTabBehavior: React.FC = () => {
  const primaryColorScheme = usePrimaryColorScheme()
  const { isNewTabFormEnabled, setIsNewTabFormEnabled } = useSettingsContext()

  return typeof isNewTabFormEnabled !== "undefined" ? (
    <FormControl>
      <FormLabel htmlFor={ELEMENT_ID}>
        Ask for tab name when creating new tab?
      </FormLabel>
      <Switch
        id={ELEMENT_ID}
        isChecked={isNewTabFormEnabled}
        onChange={(event) => setIsNewTabFormEnabled(event.target.checked)}
        colorScheme={primaryColorScheme}
      />
    </FormControl>
  ) : null
}

export default NewTabBehavior
